import React from 'react';
import propTypes from 'prop-types';
import * as routes from '../../constants/routes';
import { Link } from 'react-router-dom';
import {translate} from '@yocasa/data-service';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import './Profile.css';

const ProfileLink = ({isAuthenticated, avatar, hide, name}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={hide ? 'profile-link-hidden' : ''}>
            {isAuthenticated 
                ? <div>
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                        <img className="avatar" src={avatar} alt="user" height="40" width="40"/>
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem className="greeting">
                            <span>{ `${translate('Hello')}  ${name}` }</span>                           
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <Link className="nostyle" to={routes.USER_PROFILE}>{translate('Profile')}</Link>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <Link className="nostyle" to={routes.SIGN_OUT}>{translate('Logout')}</Link>
                        </MenuItem>
                    </Menu>
                </div>
                : <Button>
                    <Link to={routes.SIGN_IN} className="sign-in" >
                        {translate('Sign in')}
                    </Link>
                </Button>
            }

        </div>
    );
};

ProfileLink.propTypes = {
    isAuthenticated: propTypes.bool.isRequired,
    avatar: propTypes.string,
    hide: propTypes.bool || propTypes.string,
    name: propTypes.string
};

export default ProfileLink;