import React from 'react';
import {doSignOut, sendMessageEvent} from '@yocasa/data-service';
import {messageTypes} from '@yocasa/common';
import {HOME} from '../../constants/routes';
import { Redirect } from 'react-router-dom';

const Logout = () => {
    sendMessageEvent({
        type: messageTypes.GUEST_SIGNED_OUT
    });
    doSignOut();
    return (
        <div>
            <Redirect to={HOME}/>
        </div>
    );
};

export default Logout;
