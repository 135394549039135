export default [ {
    name: 'AC',
    important: true
}, {
    name: 'Pool',
    important: true
}, {
    name: 'Elevators',
    important: true
}, {
    name: 'wifi',
    important: true
}, {
    name: '24/7 Front Desk',
    important: true
},{
    name: 'TV',
    important: true
},{
    name: 'Washing Machine'
},{
    name: 'coffee maker'
},{
    name: 'kitchen utensils'
},{
    name: 'bathroom toiletries'
},
{
    name: 'bed linens'
},{
    name: 'Pool towels'
}];