import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import {translate, setCurrentPage, resetReservation} from '@yocasa/data-service';
import UserInput from './UserInput';
import DetailsInput from './DetailsInput';
import PriceInput from './PriceInput';
import FeesInput from './FeesInput';
import DiscountsInput from './DiscountsInput';
import {DETAILS_PAGE, CONFIRMATION_PAGE, PRICE_PAGE, FEES_PAGE, DISCOUNTS_PAGE} from './components/reservation-input-pages';
import ConfirmReservation from './confirmationPage';
import {CREATE_RESERVATION, HOME} from '../../constants/routes';
import PropertyName from '../utils/PropertyName';
import './reservationPage.css';

const ReservationPage = (props) => {
    
    useEffect(() => { 
        props.dispatch({
            type: 'SET_PAGE_TITLE',
            pageTitle: translate('Create Reservation'),
            pageRoute: CREATE_RESERVATION
        }); 
    },[]);


    const gotoPage = (page) => {
        props.dispatch(setCurrentPage(page));
    };

    const ShowPage = () => {
        let page = null;
        switch(props.currentPage) {
            case DETAILS_PAGE:
                page = <DetailsInput gotoPage={gotoPage}/>;
                break;
            case PRICE_PAGE:
                page = <PriceInput gotoPage={gotoPage}/>;
                break;
            case FEES_PAGE:
                page = <FeesInput gotoPage={gotoPage}/>;
                break;
            case DISCOUNTS_PAGE:
                page = <DiscountsInput gotoPage={gotoPage}/>;
                break;
            case CONFIRMATION_PAGE:
                page =<ConfirmReservation gotoPage={gotoPage} cancel={cancelReservation}/>;
                break;
            default:
                page =<UserInput gotoPage={gotoPage}/>;
        }
        return page;
    };

    const cancelReservation = (e, message) => {
        props.dispatch(resetReservation());
        props.history.push({
            pathname: HOME,
            state: {
                snack: message || ''
            }
        });
    };

    return (
        <div className="reservation-container">
            <div className="close" onClick={cancelReservation}>X</div>
            <PropertyName name={props.propertyName} />
            <ShowPage />
        </div>
    );
};
ReservationPage.propTypes = {
    currentPage: propTypes.number,
    dispatch: propTypes.func,
    propertyName: propTypes.string,
    history: propTypes.object
};

const mapStateToProps = (state) => {
    const {currentPage} = state.reservation;

    return {
        currentPage,
        propertyName: state.property.name
    };
};
 
export default connect(mapStateToProps)(ReservationPage);
