/* eslint-disable indent */
import React from 'react';
import { store, getCurrentProperty } from '@yocasa/data-service';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import Home from './components/home-page/Home';
import LoginPage from  './components/login-page/LoginPage';
import Navigation from './components/navigation/Navigation';
import Logout from './components/login-page/Logout';
import CalendarPage from './components/calendar/Calendar';
import * as routes from './constants/routes';
import ProtectedRoute from './components/utils/ProtectedRoute';
import ProfilePage from './components/user/Profile';
import ShowInvoice from './components/invoice-page/ShowInvoice';
import PropertyReservations from './components/reservation-list-page/ListPropertyReservations';
import CreateReservation from './components/reservations/createReservation';
import EditPictures from './components/pictures/edit-pictures';
import PropertyPhotos from './components/pictures/photos';
import authenticate from './common/authentication';
import PropertyBasics from './components/features/propertyBasics';
import PropertySpaces from './components/features/livingSpaces';
import PropertyAmenities from './components/features/propertyAmenities';
import PropertyRules from './components/features/propertyRules';
import PropertyPricing from './components/features/pricing/pricing';
import AdminPage from './components/admin-page/Admin';

const init = (pid) => {
    
    authenticate();
    getCurrentProperty(pid);    
};

const Main = () => {
    const pid = 1;
    init(pid);
    return  (<Router history={createBrowserHistory()}>
        <Provider store={store}>
            <CssBaseline />
            <React.Fragment>
                {/* <React.StrictMode> */}
                <div className="yc-container">
                    <Navigation />
                    <Switch>
                        <Route exact path={routes.SIGN_IN} component={LoginPage} />
                        <Route exact path={routes.SIGN_OUT} component={Logout} />
                        <ProtectedRoute exact path={routes.ADMIN} component={AdminPage} />
                        <ProtectedRoute path={routes.CALENDAR} component={CalendarPage} />
                        <ProtectedRoute path={routes.INVOICE} component={ShowInvoice} />
                        <ProtectedRoute path={routes.PROPERTY_RESERVATIONS} component={PropertyReservations} />
                        <ProtectedRoute path={routes.CREATE_RESERVATION} component={CreateReservation} />
                        <ProtectedRoute path={routes.USER_PROFILE} component={ProfilePage} />
                        <ProtectedRoute path={routes.EDIT_PICTURES} component={EditPictures} />
                        <ProtectedRoute path={routes.PROPERTY_BASICS} component={PropertyBasics} />
                        <ProtectedRoute path={routes.PROPERTY_SPACES} component={PropertySpaces} />
                        <ProtectedRoute path={routes.PROPERTY_AMENITIES} component={PropertyAmenities} />
                        <ProtectedRoute path={routes.PROPERTY_RULES} component={PropertyRules} />
                        <ProtectedRoute path={routes.PROPERTY_PRICING} component={PropertyPricing} />
                        <ProtectedRoute path={routes.PROPERTY_PHOTOS} component={PropertyPhotos} />
                        <Route component={Home} />
                    </Switch>  
                </div>
                {/* </React.StrictMode> */}
            </React.Fragment>
        </Provider>
    </Router>
);
};

export default Main;