import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import { HOME, SIGN_IN } from '../../constants/routes';
import Messages from '../messages/messages';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Paper from '@material-ui/core/Paper';
import {translate} from '@yocasa/data-service';
import TabPanel from '../utils/TabPanel';
import {connect} from 'react-redux';
import Calendar from '../calendar/Calendar';
import Photos from '../pictures/photos';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import './Home.css';

const Home = ({pid, tab, propertyName, isAuthenticated, dispatch, history, snack}) => {
    const [selectedTab, setSelectedTab] = React.useState(tab || 1);
    const [open, setOpen] = React.useState(true);

    useEffect(() => { 
        dispatch({
            type: 'SET_PAGE_TITLE',
            pageTitle: propertyName,
            pageRoute: HOME
        }); 
    },[propertyName]);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setOpen(false);
    };
      
    return (
        <div>
            {isAuthenticated 
                ? <div className="home_container">
                    <Paper square>
                        <Tabs
                            value={selectedTab}
                            indicatorColor="primary"
                            onChange={handleTabChange}
                            textColor="primary"
                            centered>
                            <Tab label={translate('Guest Activity')} />
                            <Tab label={translate('Calendar')} />
                            <Tab label={translate('Photos')} />
                        </Tabs>
                    </Paper> 
                    <TabPanel value={selectedTab} index={0}>
                        {pid && <Messages pid={pid} /> }
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                        {pid && <Calendar /> }
                    </TabPanel>
                    <TabPanel value={selectedTab} index={2}>
                        {pid && <Photos from="home" /> }
                    </TabPanel>
                </div>
                : history.push(SIGN_IN)}

            {snack && <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={snack}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />}               
        </div>
        
    );    
};

Home.propTypes = {
    pid: propTypes.number,
    propertyName: propTypes.string,
    isAuthenticated: propTypes.bool,
    dispatch: propTypes.func,
    history: propTypes.object,
    tab: propTypes.number,
    snack: propTypes.any
};

const mapStateToProps = (state, ownProps) => {
    const {property, user} = state;
    return {
        tab: ownProps.location && ownProps.location.state ? ownProps.location.state.tab : 1,
        snack: ownProps.location && ownProps.location.state && ownProps.location.state.snack || false,
        pid: property.id,
        propertyName: property.name,
        isAuthenticated: user.isAuthenticated
    };
};

export default connect(mapStateToProps)(Home);
