import React from 'react';
import propTypes from 'prop-types';
import {translate, saveCurrentProperty, setReservation} from '@yocasa/data-service';
import {connect} from 'react-redux';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import {refreshBrowser} from '@yocasa/common';
import './settings.css';

const DisplaySettings = (props) => {
    const [cur, setCurrency] = React.useState(props.currency);
    const [lan, setLanguage] = React.useState(props.language);
    const currencyOnly = props.type && props.type === 'currency';
    const languageOnly = props.type && props.type === 'language';

    const handleChange = (value, setting) => {
        switch(setting) {
            case 'Currency':
                setCurrency(value);
                props.for === 'reservation'
                    ? props.dispatch(setReservation({currency: value}))
                    : saveCurrentProperty(props.pid, {defaultCurrency: value});
                break;
            case 'Language':
                setLanguage(value);
                saveCurrentProperty(props.pid, {dataLanguage: value});
                refreshBrowser();
                break;
            default: break;
        }
        if (props.closeDrawer) props.closeDrawer(true);
    };


    const settingChooser = (settingList, setting) => {
        return (
            <FormControl>
                <InputLabel>{translate(setting)}</InputLabel>
                <Select
                    value={setting === 'Currency' ? cur : lan}
                    onChange={(e) => handleChange(e.target.value, setting)}
                >{Object.keys(settingList).map(item => <MenuItem key={item} value={item}>{setting === 'Currency' ? props.supportedCurrencies[item] : props.supportedLanguages[item]}</MenuItem>)}
                </Select>
            </FormControl>
        );
    };

    return ( 
        <div>
            <div className="settings-container">
                <div className="items">
                    { !languageOnly && <div className="item">{settingChooser(props.supportedCurrencies, 'Currency')}</div>}
                    { !currencyOnly && <div className="item">{settingChooser(props.supportedLanguages, 'Language')}</div>}
                </div>
            </div> 
            <hr/>
        </div>
    );
};
DisplaySettings.propTypes = {
    language: propTypes.string,
    currency: propTypes.string,
    supportedCurrencies: propTypes.object,
    supportedLanguages: propTypes.object,
    dispatch: propTypes.func,
    closeDrawer: propTypes.func,
    type: propTypes.string,
    pid: propTypes.number,
    for: propTypes.string
};

const mapStateToProps = (state,ownProps) => {
    return {
        language: state.property.dataLanguage,
        currency: ownProps.for === 'reservation' && 'currency' in state.reservation ? state.reservation.currency : state.property.defaultCurrency, 
        supportedCurrencies: state.property.supportedCurrencies,
        supportedLanguages: state.settings.supportedLanguages,
        pid: state.property.id
    };
};

export default connect(mapStateToProps)(DisplaySettings);
