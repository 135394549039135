import React from 'react';
import propTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {RightArrow} from '../../utils/icons';
import {translate} from '@yocasa/data-service';
import numeral from 'numeral';
import {DETAILS_PAGE} from './reservation-input-pages';
import {getCurrencyPrice} from '@yocasa/common';

const Details = ({reservation, gotoPage, ownerName}) => {
    const allGuests = (guests) => {
        let numberOfGuests = 0;
        for (const guest in guests) {
            numberOfGuests += guests[guest];
        }
        return numberOfGuests;
    };

    const itemsAmount = (items) => {
        let amount = 0;
        items.forEach(item => {
            amount += item.isAmount ? item.value : item.value * reservation.absolutePrice/100; 
        });
        return amount;
    };

    return (
        <div>
            <div className="reservation-details-container">
                <div style={{textAlign: 'center', fontWeight: '700'}}>{`${translate('Reservation for')} ${ownerName}`}</div>
                <div className="reservation-details-change">
                    <Button color="secondary" 
                        onClick={()=> gotoPage(DETAILS_PAGE)}
                    >{translate('Change')}
                    </Button>
                </div>
                <div className="reservation-details-dates">
                    <div>
                        <Typography>{moment(reservation.startDate).format('ddd, MMM Do')}</Typography>
                    </div>
                    <RightArrow />
                    <div>
                        <Typography>{moment(reservation.endDate).format('ddd, MMM Do')}</Typography>
                    </div>
                </div>
                <div className="reservation-details-guests">
                    <Typography>{`${translate('Nights')}: ${reservation.days}`}</Typography>
                    <Typography>{`${translate('Guests')}: ${allGuests(reservation.guests)}`}</Typography>
                    <Typography>{`${translate('Currency')}: ${reservation.currency}`}</Typography>
                </div>
                {(reservation.absolutePrice > 0) && <div className="reservation-details-guests" style={{justifyContent: 'space-between'}}>
                    <Typography>{`${translate('Per night')}: ${numeral(getCurrencyPrice(reservation.price*100, reservation.currency)/100).format('$0,0')}`}</Typography>
                    <Typography>{`${translate('Total')}: ${numeral(getCurrencyPrice(reservation.absolutePrice*100, reservation.currency)/100).format('$0,0')}`}</Typography>
                </div>}
                {(reservation.addFees || reservation.addDiscounts) && <div className="reservation-details-items">
                    {reservation.addFees && <Typography>{`${translate('Fees')}: +${numeral(getCurrencyPrice(itemsAmount(reservation.fees)*100, reservation.currency)/100).format('$0,0')}`}</Typography>}
                    {reservation.addDiscounts && <Typography>{`${translate('Discounts')}: -${numeral(getCurrencyPrice(itemsAmount(reservation.discounts)*100, reservation.currency)/100).format('$0,0')}`}</Typography>}
                </div>}
                {(reservation.totalPrice > 0) && <div className="reservation-details-price">
                    <Typography className="bold">{`${translate('Net')}: ${numeral(getCurrencyPrice(reservation.totalPrice*100, reservation.currency)/100).format('$0,0')} ${reservation.currency}`}</Typography>
                </div>
                }
            </div>  
        </div>
    );
};
Details.propTypes = {
    ownerName: propTypes.string,
    reservation: propTypes.object,
    gotoPage: propTypes.func
};

const mapStateToProps = (state) => {
    return (
        {
            reservation: state.reservation,
            ownerName: state.reservation.user.name
        }
    );
};


export default connect(mapStateToProps)(Details);