import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import GuestLine from './GuestLine';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import {translate, setGuests } from '@yocasa/data-service';

const useStyles = makeStyles(() => ({
    rightIcon: {
        marginLeft: 40
    },
    guestInput: {
        marginTop: 10,
        marginBottom: 10
    },
    iconSmall: {
        fontSize: 20
    },
    guestButtonLabelPopoverOpened: {
        backgroundColor: '#99ede6'
    },
    popoverButton: {
        width: '100%'
    },
    guestCard: {
        paddingRight: 5,
        paddingLeft: 5
    },
    closePopoverButton: {
        position: 'relative',
        left: '80%'
    },
    maxGuestsLabel: {
        wordWrap: 'break-word',
        marginTop: 15,
        paddingLeft: 5  
    }
}));

const GuestInput = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const guests = props.adults + props.children + props.infants;

    const guestLabel = () => {
        return `${guests} ${translate('guests')}`;
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const increaseNumberOfGuests = ev => {
        const guestType = ev.currentTarget.dataset.guesttype;
        if ( (guests - props.infants) < props.maxGuests || guestType === 'Infants') {
            let change;
            switch(guestType) {
                case 'Adults':
                    change = props.adults + 1;
                    props.dispatch(setGuests({
                        adults: change <= props.maxGuests ? change : props.adults,
                        children: props.children,
                        infants: props.infants
                    }));
                    break;
                case 'Children':
                    change = props.children + 1;
                    props.dispatch(setGuests({
                        adults: props.adults,
                        children: change <= props.maxGuests ? change : props.children,
                        infants: props.infants
                    }));
                    break;
                case 'Infants':
                    change = props.infants + 1;
                    props.dispatch(setGuests({
                        adults: props.adults,
                        children: props.children,
                        infants: change <= props.maxGuests ? change : props.infants
                    }));
                    break;
                default:
                    break;
            }    
        }
    };
    const decreaseNumberOfGuests = ev => {
        if (guests > 0) {
            let change;
            switch(ev.currentTarget.dataset.guesttype) {
                case 'Adults':
                    change = props.adults - 1;
                    props.dispatch(setGuests({
                        adults: change >= 0 ? change : props.adults,
                        children: props.children,
                        infants: props.infants
                    }));
                    break;
                case 'Children':
                    change = props.children - 1;
                    props.dispatch(setGuests({
                        adults: props.adults,
                        children: change >= 0 ? change : props.children,
                        infants: props.infants
                    }));
                    break;
                case 'Infants':
                    change = props.infants - 1;
                    props.dispatch(setGuests({
                        adults: props.adults,
                        children: props.children,
                        infants: change >= 0 ? change : props.infants
                    }));
                    break;
                default:
                    break;
            }    
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const id = open ? 'simple-popover' : undefined;
    
    return (
        <div className={classes.guestInput}>
            {translate('Guests')}<br/>
            <Button
                aria-describedby={id} 
                variant="outlined" color="default" className={classes.popoverButton} 
                onClick={handleClick}>
                <span className={ open ? classes.guestButtonLabelPopoverOpened : classes.guestButtonLabelPopoverClosed}>{guestLabel()}</span>
                { open 
                    ? <ExpandLess className={classes.rightIcon} /> 
                    : <ExpandMore className={classes.rightIcon} />
                }
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Card className={classes.guestCard}>
                    <CardContent>
                        <GuestLine 
                            label="Adults" 
                            value={props.adults} 
                            info=''
                            decreaseNumberOfGuests={decreaseNumberOfGuests}
                            increaseNumberOfGuests={increaseNumberOfGuests} />
                        <GuestLine 
                            label="Children" 
                            value={props.children}
                            info={`${translate('ages')} 2-12`}
                            decreaseNumberOfGuests={decreaseNumberOfGuests}
                            increaseNumberOfGuests={increaseNumberOfGuests} />
                        <GuestLine 
                            label="Infants" 
                            value={props.infants}
                            info={`${translate('under')} 2`}
                            decreaseNumberOfGuests={decreaseNumberOfGuests}
                            increaseNumberOfGuests={increaseNumberOfGuests} />
                        <div className={classes.maxGuestsLabel}>{`${props.maxGuests} ${translate('maximum. Infants stay free and dont count toward the number of guests')}.`}</div>
                    </CardContent>
                    <CardActions>
                        <Button color="primary" size="small" className={classes.closePopoverButton} onClick={handleClose}>
                            {translate('Close')}
                        </Button>
                    </CardActions>
                </Card>
            </Popover>
        </div>
    );
};

GuestInput.propTypes = {
    dispatch: propTypes.func.isRequired,
    adults: propTypes.number,
    children: propTypes.number,
    infants: propTypes.number,
    maxGuests: propTypes.number
};

const mapStateToProps = (state) => {
    const reservation = state.reservation;
    const { occupancy:maxGuests } = state.property;

    const {adults, children, infants} = reservation.guests;
    return {
        adults,
        children,
        infants,
        maxGuests
    };
};

export default connect(mapStateToProps)(GuestInput);