import React from 'react';
import './LoginForm.css';
import propTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import {translate} from '@yocasa/data-service';
import {validateEmail} from '@yocasa/common';

const initialState = {
    email: '',
    password1: '',
    invalidEmail: false
};

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState
        };
    }

    static propTypes = {
        login: propTypes.func.isRequired,
        toggleForm: propTypes.func.isRequired,
        error: propTypes.object,
        message: propTypes.string,
    }
    
    toggleForm = (action) => {
        this.setState({password1: ''});
        this.props.toggleForm(action);
    }

    handleInput = (e) => {
        const field = e.currentTarget.dataset.field;
        const value = e.currentTarget.value;

        if (field === 'email') {
            this.setState({
                invalidEmail: value.length ? !validateEmail(value) : false
            });
        }
        e.preventDefault();
        this.setState({
            [field]: value
        });
    }

    componentWillUnmount() {
        this.setState(initialState);
    }

    login = () => {
        const isLoginInvalid =
            this.state.password1.length < 6  ||
            this.state.email === '';

        return (
            <form name="login">
                {!this.props.error && this.props.message &&
                    <p className="info-message">
                        {translate(this.props.message)}
                    </p>

                }
                <input type="email" value={this.state.email} data-field="email" 
                    name="email" onChange={this.handleInput}  placeholder={translate('email')}/>
                <input type="password" value={this.state.password1} data-field="password1" 
                    name="password" onChange={this.handleInput} placeholder={translate('password')}/>
                <button className={'submit ' + (isLoginInvalid ? 'disabled' : '')} 
                    disabled = {isLoginInvalid}
                    onClick={(e) => {e.preventDefault(); this.props.login(this.state);}}>
                    {translate('Login')}
                </button>
                <p className="message">{translate('Not registered?')} 
                    <Link
                        className="link"
                        component="button"
                        variant="body2"
                        onClick={(e) => { e.preventDefault(); this.toggleForm('Create Account'); }}
                    >{translate('Create an account')}
                    </Link>
                </p>
                <p className="message">{translate('Forgot password?')} 
                    <Link
                        className="link"
                        component="button"
                        variant="body2"
                        onClick={(e) => { e.preventDefault(); this.toggleForm('Reset Password'); }}
                    >{translate('Reset your password')}
                    </Link>
                </p>
                {this.props.error && 
                    <p className="error">{
                        this.props.error.message ? this.props.error.message : translate('Unknown Error')
                    }</p>
                }
            </form>
        );
    }

    render() {
        return ( 
            <div className="login-page">
                <div className="form">
                    { this.login() }
                </div>
            </div>
        );    
    }
}

export default LoginForm;
