import React from 'react';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import propTypes from 'prop-types';
import './SpinnerModal.css';

export default function SimpleModal({isOpen}) {
    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        function tick() {
            setProgress(oldProgress => (oldProgress >= 100 ? 0 : oldProgress + 1));
        }

        const timer = setInterval(tick, 20);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        typeof isOpen !== 'undefined' &&
        <Modal
            aria-labelledby="spinner modal"
            aria-describedby="loading spinner modal"
            open={isOpen}
        >
            <div className="modal">
                <CircularProgress className="spinner" variant="determinate" value={progress} />
                <SimpleModal />
            </div>
        </Modal>
    );
}

SimpleModal.propTypes = {
    isOpen: propTypes.bool
};