import React, {useState} from 'react';
import {translate, setReservationUser} from '@yocasa/data-service';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import {connect} from 'react-redux';
import propTypes from 'prop-types';
import {DETAILS_PAGE} from './components/reservation-input-pages';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import {validateEmail, countries, getCountry} from '@yocasa/common';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        marginBottom: 30
    }
}));

const UserInput = ({gotoPage, dispatch, owner}) => {
    const [name, setName] = useState(owner ? owner.name : '');
    const [email, setEmail] = useState(owner ? owner.email : '');
    const [phone, setPhone] = useState(owner ? owner.phone : '');
    const [country, setCountry] = useState(owner ? getCountry({name: owner.country}) : '');
    const [validEmail, setValidEmail] = useState(owner ? true : false);
    const classes = useStyles();

    const handleInput = (e, setValue) => {
        const field = e.currentTarget.dataset.field;
        const value = e.currentTarget.value;

        if (field === 'email') {
            setValidEmail(value.length ? validateEmail(value) : false);
        }
        e.preventDefault();
        setValue(value);
    };

    const isInformationIncomplete = () => {
        return email === '' || !validEmail || phone === '' || phone.length < 6 || name === '' || country === '';
    };

    const handleCountryChange = (e) => {
        const countrySelected = e.target.value;
        setCountry(countrySelected);
        setPhone(`+${countrySelected.phoneCode}`);
        document.getElementById('telephone').focus();
    };
 
    const submitInput = (e) => {
        e.preventDefault();
        dispatch(setReservationUser({
            name,
            email,
            country: country.value,
            phone
        }));
        gotoPage(DETAILS_PAGE);    
    };

    const phoneInputHandler = (value, country, e, formattedValue) => {
        setPhone(formattedValue);
        e.preventDefault();
    };

    return ( 
        <div>
            <form className="page2-container">
                <Typography variant="subtitle2" style={{fontWeight: '700'}}>{translate('Reservation Owner')}</Typography>
                <TextField
                    name="name"
                    id="name"
                    required
                    inputProps={{'data-field':'name', autoFocus: 'autofocus', autoComplete:'off'}}
                    placeholder={translate('Name')}
                    value={name}
                    onChange={(e) => handleInput(e, setName)} 
                    color="primary"
                    fullWidth
                    margin="normal"
                />
                <FormControl className={classes.formControl}>
                    <InputLabel>{translate('Country of Residence')}</InputLabel>
                    <Select
                        value={country}
                        onChange={handleCountryChange}
                        fullWidth>
                        <MenuItem value="" disabled>{translate('Country of Residence')}</MenuItem>    
                        {countries.map(item => <MenuItem key={item.map} value={item}>{item.value}</MenuItem>)}
                    </Select>        
                </FormControl>
                {phone && <span style={{marginBottom:'5px', color:'grey'}}>{translate('Phone Number')}</span>}    
                <PhoneInput
                    placeholder={translate('Phone Number')}
                    inputProps={{
                        id: 'telephone',
                        required: true,
                        style:{width: '100%'}
                    }}
                    value={ phone }
                    onChange={phoneInputHandler}
                />
                <TextField
                    name="email"
                    id="email"
                    required
                    error={!validEmail}
                    inputProps={{'data-field':'email', autoComplete:'off'}}
                    type="email"
                    placeholder={translate('Email Address')}
                    value={email}
                    onChange={(e) => handleInput(e, setEmail)} 
                    color="primary"
                    fullWidth
                    margin="normal"
                />        
                <div className="buttons">
                    <Button 
                        color="primary" 
                        variant="contained" 
                        disabled = {isInformationIncomplete()}
                        type="submit"
                        size="small"
                        fullWidth
                        onClick={submitInput}>{translate('Continue')}
                    </Button>
                </div>
            </form>
        </div>
    );
};
UserInput.propTypes = {
    dispatch: propTypes.func,
    gotoPage:  propTypes.func,
    owner: propTypes.object
};

const mapStateToProps = (state) => ({
    owner: state.reservation.user
});

export default connect(mapStateToProps)(UserInput);