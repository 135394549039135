import React, {useState} from 'react';
import propTypes from 'prop-types';
import {translate, saveCurrentProperty} from '@yocasa/data-service';
import Button from '@material-ui/core/Button';
import {PROPERTY_RULES} from '../../constants/routes';
import {connect} from 'react-redux';
import TabPanel from '../utils/TabPanel';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Paper from '@material-ui/core/Paper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Accordion, AccordionSummary, AccordionDetails} from '../utils/Accordion';
import yesNoRules from '../../constants/allowRulesList';

import './features.css';

const Rules = ({pid, checkin, checkout, occupancy, minDays, maxDays, daysNotice, rules, dispatch}) => {
    const [currentTab, setCurrentTab] = React.useState(0);
    const [expanded, setExpanded] = React.useState(0);
    const [_checkin, setCheckin] = useState(checkin);
    const [_checkout, setCheckout] = useState(checkout);
    const [_occupancy, setOccupancy] = useState(occupancy);
    const [_rules] = useState(rules || {});
    const [allowValue, setAllowValue] = React.useState(false);
    const [dataChanged, setDataChanged] = React.useState(false);
    const [_minDays, setMinDays] = useState(minDays);
    const [_maxDays, setMaxDays] = useState(maxDays);
    const [_daysNotice, setDaysNotice] = useState(daysNotice);

    React.useEffect(() => {
        dispatch && dispatch({
            type: 'SET_PAGE_TITLE',
            pageTitle: translate('Property Rules'),
            pageRoute: PROPERTY_RULES
        }); 
    }, [pid]);

    const handleAccordionChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };    
    
    const saveRules = () => {
        saveCurrentProperty(pid, {
            checkin: _checkin,
            checkout: _checkout,
            occupancy: _occupancy,
            rules: _rules,
            minReservationDays: _minDays,
            maxReservationDays: _maxDays,
            reservationDaysNotice: _daysNotice 
        });
        setDataChanged(false);
    };

    const yesNoRulesChanged = (rule, allow) => {
        if (!allow && !(`${rule.number}` in _rules)) {
            _rules[rule.number] = rule.showGuests;
        }
        else if (allow && (`${rule.number}` in _rules)) {
            delete _rules[rule.number];
        }
        setDataChanged(true);
        setAllowValue(!allowValue);
    };

    const handleAllowChange = (value, rule) => {
        const allow = value === 'true' || value === true;
        yesNoRulesChanged(rule, allow);
    };

    const handleChange = (value, setChanged) => {
        setChanged(value);
        setDataChanged(true);
    };

    const allowAccordion = (rule, index) => {
        const value = rules && !(`${rule.number}` in rules);
        return (
            <Accordion square expanded={expanded === index} onChange={handleAccordionChange(index)}>
                <AccordionSummary className="accordion-header" expandIcon={<ExpandMoreIcon />} >
                    <Typography>{translate(rule.name)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FormControl className={index%2 === 0 ? 'rule even' : 'rule odd'}>
                        <div className="title">{translate(`${rule.name} ${rule.adminQuestion}`)}</div>
                        <RadioGroup name={rule.name} value={value} onChange={(e) => handleAllowChange(e.target.value, rule)}>
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </AccordionDetails>
            </Accordion>
        );
    };

    return (
        <div className="basics-container">
            <Paper square>
                <Tabs
                    value={currentTab}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                    onChange={handleTabChange}>
                    <Tab label={translate('Allow')} />
                    <Tab label={translate('in/out Times')} />
                    <Tab label={translate('Occupancy')} />
                    <Tab label={translate('Reservations')} />
                    
                </Tabs>
            </Paper> 
            <TabPanel value={currentTab} className="basics" index={0}>
                {yesNoRules.map((rule, index) => <div key={index}>{allowAccordion(rule, index)}</div>)}
            </TabPanel>
            <TabPanel value={currentTab}  className="basics" index={1}>
                <div style={{marginTop: '15px'}}>
                    <label style={{marginRight: '15px'}}>{translate('Check-in time: ')}</label>
                    <TextField style={{top: '-8px'}} type="time" value={_checkin} onChange={(e)=> handleChange(e.target.value, setCheckin)}/>
                </div>
                <div style={{marginTop: '30px'}}>
                    <label style={{marginRight: '15px'}}>{translate('Check-out time: ')}</label>
                    <TextField style={{top: '-8px'}} type="time" value={_checkout} onChange={(e)=> handleChange(e.target.value, setCheckout)}/>
                </div>
            </TabPanel>
            <TabPanel value={currentTab} className="basics" index={2}>
                <div style={{marginTop: '15px'}}>
                    <label style={{marginRight: '15px'}}>{translate('Maximum Occupancy: ')}</label>
                    <TextField style={{top: '-10px'}} type="number" inputProps={{max: '20', min: '1', size: 2}} 
                        value={_occupancy} onChange={(e)=> handleChange(e.target.value, setOccupancy)}/>
                </div>
            </TabPanel>
            <TabPanel value={currentTab} className="basics" index={3}>
                <div style={{marginTop: '15px'}}>
                    <label style={{marginRight: '15px'}}>{translate('Minimum Reservation Days: ')}</label>
                    <TextField style={{top: '-10px'}} type="number" inputProps={{max: '60', min: '1', size: 2}} 
                        value={_minDays} onChange={(e)=> handleChange(e.target.value, setMinDays)}/>
                </div>
                <div style={{marginTop: '15px'}}>
                    <label style={{marginRight: '15px'}}>{translate('Maximum Reservation Days: ')}</label>
                    <TextField style={{top: '-10px'}} type="number" inputProps={{max: '999', min: '1', size: 2}} 
                        value={_maxDays} onChange={(e)=> handleChange(e.target.value, setMaxDays)}/>
                </div>
                <div style={{marginTop: '15px'}}>
                    <label style={{marginRight: '15px'}}>{translate('Days notice before check-in: ')}</label>
                    <TextField style={{top: '-10px'}} type="number" inputProps={{max: '20', min: '0', size: 2}} 
                        value={_daysNotice} onChange={(e)=> handleChange(e.target.value, setDaysNotice)}/>
                </div>
            </TabPanel>
            <div style={{textAlign: 'center'}}>
                <Button disabled={!dataChanged} variant="contained" color="secondary" onClick={saveRules}>{translate('Save')}</Button>
            </div>
        </div>
    );
};

Rules.propTypes = {
    pid: propTypes.number, 
    checkin: propTypes.string, 
    checkout: propTypes.string, 
    occupancy: propTypes.number, 
    minDays: propTypes.number, 
    maxDays: propTypes.number, 
    daysNotice: propTypes.number, 
    dispatch: propTypes.func,
    rules: propTypes.object
};

const mapStateToProps = (state) => {
    const {property} = state;
    return {
        rules: property.rules,
        pid: property.id,
        checkin: property.checkin || '10:00',
        checkout: property.checkout || '14:00',
        occupancy: property.occupancy || 4,
        minDays: property.minReservationDays || 1,
        maxDays: property.maxReservationDays || 360,
        daysNotice: property.reservationDaysNotice || 1,
    };
};

export default connect(mapStateToProps)(Rules);