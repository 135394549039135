import React, {useState} from 'react';
import propTypes from 'prop-types';
import {translate, saveCurrentProperty} from '@yocasa/data-service';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {PROPERTY_BASICS} from '../../constants/routes';
import {connect} from 'react-redux';
import TabPanel from '../utils/TabPanel';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Paper from '@material-ui/core/Paper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import propertyTypes from '../../constants/propertyTypes';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Settings from '../settings/settings';
import Dialog from '@material-ui/core/Dialog';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Accordion, AccordionSummary, AccordionDetails} from '../utils/Accordion';

import './features.css';

const PropertyBasics = ({dispatch, pid, name, type, size, description, address, contact, dataLanguage}) => {
    const [currentTab, setCurrentTab] = React.useState(0);
    const [expanded, setExpanded] = React.useState('name');
    const [open, setOpen] = React.useState(false);
    const [_name, setPropertyName] = useState(name);
    const [_size, setPropertySize] = useState(size);
    const [_units, setSizeUnits] = useState(true);
    const [_type, setPropertyType] = useState(type);
    const [city, setCity] = useState(address.city);
    const [country, setCountry] = useState(address.country);
    const [state, setState] = useState(address.state);
    const [street, setStreet] = useState(address.street);
    const [street2, setStreet2] = useState(address.street2);
    const [zip, setZipCode] = useState(address.zip);
    const [email, setEmail] = useState(contact.email);
    const [phone, setPhone] = useState(contact.phone);
    const [whatsapp, setWhatsapp] = useState(contact.whatsapp);
    const [telegram, setTelegram] = useState(contact.telegram);
    const [_description, setDescription] = useState(description);
    const [dataChanged, setDataChanged] = useState(false);
    const [basicsChanged, setBasicsChanged] = useState(false);
    const [addressChanged, setAddressChanged] = useState(false);
    const [contactChanged, setContactChanged] = useState(false);
    const [descriptionChanged, setDescriptionChanged] = useState(false);

    React.useEffect(() => {
        dispatch && dispatch({
            type: 'SET_PAGE_TITLE',
            pageTitle: translate('Property Basics'),
            pageRoute: PROPERTY_BASICS
        }); 
        setPropertyName(name);
        setPropertySize(size);
        setPropertyType(type);
        setCity(address.city);
        setCountry(address.country);
        setState(address.state);
        setStreet(address.street);
        setStreet2(address.street2);
        setZipCode(address.zip);
        setEmail(contact.email);
        setPhone(contact.phone);
        setWhatsapp(contact.whatsapp);
        setTelegram(contact.telegram);
        setDescription(description);
    }, [pid]);

    const handleAccordionChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const saveBasics = () => {
        const property = {dataLanguage: dataLanguage};

        if (basicsChanged) {
            property.name = _name;
            property.type = _type,
            property.size = `${_size} ${_units ? translate('square feet') : translate('square meters')}`;
        }
        if (descriptionChanged) property.description = _description;
        if (addressChanged) {
            property.address = {
                city,
                state,
                country,
                street,
                street2,
                zip
            };
        }
        if (contactChanged) {
            property.contact = {
                phone,
                email,
                whatsapp,
                telegram
            };
        }

        saveCurrentProperty(pid, property);
        setDataChanged(false);
        setBasicsChanged(false);
        setAddressChanged(false);
        setContactChanged(false);
        setDescriptionChanged(false);
    };

    const handleChange = (value, setChanged) => {
        setChanged(value);
        setDataChanged(true);
        switch(currentTab) {
            case 0:
                setBasicsChanged(true);
                break;
            case 1:
                setAddressChanged(true);
                break;
            case 2: 
                setContactChanged(true);
                break;
            case 3: 
                setDescriptionChanged(true);
                break;
            default: 
                break;
        }
    };

    return (
        <div className="basics-container">
            <Paper square>
                <Tabs
                    value={currentTab}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                    onChange={handleTabChange}>
                    <Tab label={translate('basics')} />
                    <Tab label={translate('address')} />
                    <Tab label={translate('contact')} />
                    <Tab label={translate('description')} />
                </Tabs>
            </Paper> 
            <TabPanel value={currentTab} index={0}>
                <Accordion square expanded={expanded === 'name'} onChange={handleAccordionChange('name')}>
                    <AccordionSummary className="accordion-header" expandIcon={<ExpandMoreIcon />} >
                        <Typography>{translate('Property Name')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TextField value={_name} onChange={(e)=> handleChange(e.target.value, setPropertyName)}/>
                    </AccordionDetails>
                </Accordion>
                <Accordion square expanded={expanded === 'type'} onChange={handleAccordionChange('type')}>
                    <AccordionSummary className="accordion-header" expandIcon={<ExpandMoreIcon />} >
                        <Typography>{translate('Property Type')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormControl>
                            <Select
                                value={_type}
                                onChange={(e)=> handleChange(e.target.value, setPropertyType)}
                            >
                                {propertyTypes.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
                            </Select>        
                        </FormControl>
                    </AccordionDetails>
                </Accordion>
                <Accordion square expanded={expanded === 'size'} onChange={handleAccordionChange('size')}>
                    <AccordionSummary className="accordion-header" expandIcon={<ExpandMoreIcon />} >
                        <Typography>{translate('Property Size')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{ marginRight: '20px'}}>
                            <TextField value={_size} type="number" inputProps={{size:6}} onChange={(e)=> handleChange(e.target.value, setPropertySize)}/>
                        </div>
                        <FormControl>
                            <RadioGroup value={_units} onChange={(e)=> handleChange(e.target.value === 'true' || e.target.value === true, setSizeUnits)}>
                                <FormControlLabel value={true} control={<Radio />} label="Square Feet" />
                                <FormControlLabel value={false} control={<Radio />} label="Square Meters" />
                            </RadioGroup>
                        </FormControl>
                    </AccordionDetails>
                </Accordion>
            </TabPanel>
            <TabPanel value={currentTab}  className="basics" index={1}>
                <TextField className="item" label={translate('Country')} value={country} onChange={(e)=> handleChange(e.target.value, setCountry)}/>
                <TextField className="item" label={translate('State')} value={state} onChange={(e)=> handleChange(e.target.value, setState)}/>
                <TextField className="item" label={translate('City')} value={city} onChange={(e)=> handleChange(e.target.value, setCity)}/>
                <TextField className="item" label={translate('Street')}  value={street} onChange={(e)=> handleChange(e.target.value, setStreet)}/>
                <TextField className="item" label={translate('street2')} value={street2} onChange={(e)=> handleChange(e.target.value, setStreet2)}/>
                <TextField className="item" label={translate('Zip Code')} value={zip} onChange={(e)=> handleChange(e.target.value, setZipCode)}/>
            </TabPanel>
            <TabPanel value={currentTab}  className="basics" index={2}>
                <TextField className="item" label={translate('Property email')} value={email} onChange={(e)=> handleChange(e.target.value, setEmail)}/>
                <TextField className="item" label={translate('Property phone')} value={phone} onChange={(e)=> handleChange(e.target.value, setPhone)}/>
                <TextField className="item" label={translate('WhatsApp phone')} value={whatsapp} onChange={(e)=> handleChange(e.target.value, setWhatsapp)}/>
                <TextField className="item" label={translate('Telegram username')} value={telegram} onChange={(e)=> handleChange(e.target.value, setTelegram)}/>
            </TabPanel> 
            <TabPanel value={currentTab} index={3}>
                <Dialog open={open}>
                    <p 
                        className="link-style"
                        style={{textAlign: 'right', margin: '0 10px'}}
                        onClick={()=>setOpen(false)}>x
                    </p>
                    <Settings style={{padding:'20px'}} />
                </Dialog>
                <FormControl className="description" fullWidth={true}>
                    <TextField
                        label={translate('Property Description')}
                        fullWidth={true}
                        multiline={true}
                        type="text"
                        variant="outlined"
                        rows={5}
                        rowsMax={15}
                        placeholder={translate('Information about your property for your guests')} 
                        value={_description} 
                        onChange={(e)=> handleChange(e.target.value, setDescription)}/>
                    {<FormHelperText>
                        <span 
                            className="link-style" 
                            onClick={()=>setOpen(true)}>
                            {`${dataLanguage} ${translate('Language')}`}
                        </span>
                    </FormHelperText>
                    }
                </FormControl>
            </TabPanel>
            <div style={{textAlign: 'center'}}>
                <Button disabled={!dataChanged} variant="contained" color="secondary" onClick={saveBasics}>{translate('Save')}</Button>
            </div>
        </div>
    );
};

PropertyBasics.propTypes = {
    pid: propTypes.number,
    name: propTypes.string,
    type: propTypes.string,
    description: propTypes.string,
    address: propTypes.object,
    contact: propTypes.object,
    dataLanguage: propTypes.string,
    dispatch: propTypes.func,
    size: propTypes.string
};

const mapStateToProps = (state) => {
    const {property, settings} = state;
    return {
        pid: property.id,
        name: property.name,
        type: property.type,
        dataLanguage: property.dataLanguage || settings.languageCode,
        description: property.description,
        size: property.size ? property.size.substring(0, property.size.indexOf(' ')) : 0,
        address: property.address || {},
        contact: property.contact || {}
    };
};

export default connect(mapStateToProps)(PropertyBasics);
