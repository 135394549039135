import React from 'react';
import propTypes from 'prop-types';
import allSpaces from '../../constants/livingSpacesList';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import {translate, saveCurrentProperty} from '@yocasa/data-service';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {connect} from 'react-redux';
import {PROPERTY_SPACES} from '../../constants/routes';
import './features.css';

const DisplaySpace = ({space, existing, index, updateSpaces}) => {
    const alreadyInput = existing && existing.length>0;
    const [value, setValue] = React.useState(alreadyInput);
    const [info, setInfo] = React.useState((alreadyInput && existing[0].info) || '');
    const [count, setCount] = React.useState((alreadyInput && existing[0].count) || space.count || 1);
    const [important, setImportant] = React.useState((alreadyInput && 'important' in existing[0] && existing[0].important) || false); 

    const items = [1,2,3,4,5,6,7,8,9.10];

    const handleChange = (event) => {
        const name = event.target.name.split('-')[1];
        let change = '';

        switch (name) {
            case 'exists':
                change = event.target.checked;
                setValue(change);
                if (!change) {
                    setInfo('');
                    setCount(space.count);
                    setImportant(false);
                }
                break;
            case 'count':
                change = event.target.value;
                setCount(change);
                break;
            case 'info':
                change = event.target.value;
                setInfo(change);
                break;
            case 'important':
                change = event.target.value === 'true' || event.target.value === true;
                setImportant(change);
                break;
            default:
        }

        updateSpaces(event.target.name, change);
    };

    return (
        <div  className={index%2 === 0 ? 'space even' : 'space odd'}>
            <FormGroup row>
                <FormControlLabel 
                    className="exists"
                    control={<Checkbox checked={value} name={`${space.name}-exists`} onChange={handleChange} />}
                    label={translate(space.name)}
                />

                {value && space.count && <div>
                    <FormControl className="count">
                        <Select
                            name={`${space.name}-count`}
                            value={count}
                            onChange={handleChange}
                        >
                            {items.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
                        </Select>        
                    </FormControl>
                </div>}
            </FormGroup>
            
            {value && <div className="important">
                <div className="title">{translate('Highligh in cover page')}</div>
                <RadioGroup name={`${space.name}-important`} value={important} onChange={handleChange}>
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                </RadioGroup>            
            </div>}

            {value && <div className="info">
                <FormControl>
                    <label>{translate('Description')}</label>
                    <TextareaAutosize
                        name={`${space.name}-info`} placeholder={`${translate('Optional information about the')} ${translate(space.name)}`} value={info} onChange={handleChange}/>
                </FormControl>
            </div>}            
        </div>
    );
};
DisplaySpace.propTypes = {
    space: propTypes.object,
    existing: propTypes.any,
    updateSpaces: propTypes.func,
    index: propTypes.number
};

const LivingSpaces = ({dispatch, pid, spaces}) => {
    const [dataChanged, setDataChanged] = React.useState(false);

    React.useEffect(() => {
        dispatch && dispatch({
            type: 'SET_PAGE_TITLE',
            pageTitle: translate('Property Spaces'),
            pageRoute: PROPERTY_SPACES
        }); 
    }, [pid]);

    const saveLivingSpaces = () => {
        saveCurrentProperty(pid, { livingAreas: spaces });
        setDataChanged(false);
    };

    const updateSpaces = (event, value) => {
        const action = event.split('-')[1];
        const name = event.split('-')[0];
        const space = spaces.filter(exist => exist.name.toLowerCase() === name.toLocaleLowerCase());

        switch (action) {
            case 'exists':
                if (value && !space || space.length === 0) spaces.push({name});
                else if (!value && space && space.length > 0) {
                    const pos = spaces.findIndex(item => item.name.toLowerCase() === name.toLowerCase()) ;
                    if (pos >= 0) spaces.splice(pos, 1);
                }
                break;
            case 'count':
                space[0].count = value;
                break;
            case 'info':
                space[0].info = value;
                break;
            case 'important':
                space[0].important = value;
                break;
            default:
        }
        setDataChanged(true);
    };

    return (
        <div className="spaces-container">
            <div className="spaces">
                {allSpaces.map((space, index) => <DisplaySpace 
                    key={space.name} 
                    space={space} 
                    existing={spaces && spaces.filter(exist => exist.name.toLowerCase() === space.name.toLocaleLowerCase())}
                    index={index}
                    updateSpaces={updateSpaces} />)}
            </div>
            <div style={{textAlign: 'center'}}>
                <Button disabled={!dataChanged} variant="contained" color="secondary" onClick={saveLivingSpaces}>{translate('Save')}</Button>
            </div>
        </div>
    );
};

LivingSpaces.propTypes = {
    spaces: propTypes.array,
    dispatch: propTypes.func,
    pid: propTypes.number
};

const mapStateToProps = (state) => {
    const {property} = state;
    return {
        spaces: property.livingAreas,
        pid: property.id
    };
};

export default connect(mapStateToProps)(LivingSpaces);