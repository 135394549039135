import React from 'react';
import propTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ReservationDetails from './components/Details';
import {translate, createAdminReservation} from '@yocasa/data-service';
import {connect} from 'react-redux';

const ConfirmReservation = ({gotoPage, cancel, dispatch}) => {

    const finish = () => {
        dispatch(createAdminReservation()); 
        setTimeout(() => cancel(null, 'Reservation Created!'), 0);
    };

    return ( 
        <div>
            <ReservationDetails gotoPage={gotoPage} />
            <div className="page2-container">
                <div className="buttons">
                    <Button
                        style={{margin:'25px auto', width:'fit-content'}}
                        variant="contained" color="primary" 
                        onClick={cancel}>{translate('Cancel Reservation')}
                    </Button>
                    <Button
                        style={{margin:'25px auto', width:'fit-content'}}
                        variant="contained" color="secondary" 
                        onClick={finish}>{translate('Create Reservation')}
                    </Button>
                </div>
            </div>
        </div>
    );
};
ConfirmReservation.propTypes = {
    gotoPage: propTypes.func,
    dispatch: propTypes.func,
    history: propTypes.object,
    cancel: propTypes.func
};
 
export default connect()(ConfirmReservation);
