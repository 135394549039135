import React from 'react';
import './LoginForm.css';
import propTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import {translate} from '@yocasa/data-service';
import {validateEmail} from '@yocasa/common';
import 'react-phone-input-2/lib/style.css';

const initialState = {
    email: '',
    invalidEmail: false
};

class ResetPasswordForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState
        };
    }

    static propTypes = {
        recover: propTypes.func.isRequired,
        toggleForm: propTypes.func.isRequired,
        error: propTypes.object,
        message: propTypes.string,
    }
    
    toggleForm = (action) => {
        this.props.toggleForm(action);
    }

    handleInput = (e) => {
        const field = e.currentTarget.dataset.field;
        const value = e.currentTarget.value;

        if (field === 'email') {
            this.setState({
                invalidEmail: value.length ? !validateEmail(value) : false
            });
        }
        e.preventDefault();
        this.setState({
            [field]: value
        });
    }

    componentWillUnmount() {
        this.setState(initialState);
    }

    recover = () => {
        const isRecoverInvalid = this.state.email === '';

        return (
            <form name="recover">
                <input type="email" value={this.state.email} data-field="email" 
                    name="email" onChange={this.handleInput}  placeholder={translate('email address')}/>
                <button className={'submit ' + (isRecoverInvalid ? 'disabled' : '')} 
                    disabled = {isRecoverInvalid}
                    onClick={(e) => {e.preventDefault(); this.props.recover(this.state);}}>
                    {translate('reset my password')}
                </button>
                <Link
                    className="link"
                    component="button"
                    variant="body2"
                    onClick={(e) => { e.preventDefault(); this.toggleForm('Login'); } }
                >{translate('Login')}
                </Link>
                {this.props.error && 
                    <p className="error">{
                        this.props.error.message ? this.props.error.message : translate('Unknown Error')
                    }</p>
                }
                {this.props.message && this.toggleForm('Login')}
            </form>
        );

    }


    render() {
        return ( 
            <div className="login-page">
                <div className="form">
                    {this.recover()}
                </div>
            </div>
        );    
    }
}

export default ResetPasswordForm;
