import React from 'react';
import propTypes from 'prop-types';
import {translate, saveCurrentProperty} from '@yocasa/data-service';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import discountList from '../../../constants/discountsList';
import Button from '@material-ui/core/Button';

import '../features.css';

const Discounts = ({discounts, pid}) => {

    const [_discounts, setDiscounts] = React.useState((discounts && discounts.length > 0) ? discounts : discountList);

    const handleChange = (index, value) => {
        let change = '';

        change = + value;
        if (change >=0) {
            const discount = {..._discounts[index]};
            discount.percent = value;
            const da = [..._discounts];
            da.splice(index, 1, discount);
            setDiscounts(da);
        }
    };

    const saveDiscounts = () => {
        saveCurrentProperty(pid, {
            discounts: _discounts
        });
    };

    return (
        <div className="pricing-container">
            <div className="prices">
                {_discounts.map((x, index) => 
                    <FormControl key={x.id} className="price">
                        <InputLabel className="title">{translate(x.description)}</InputLabel>
                        <Input
                            inputProps={{size:9}}
                            endAdornment={<InputAdornment position="end">%</InputAdornment>}
                            onChange={(e)=>handleChange(index, e.target.value)}
                            value={x.percent} /> 
                        <FormHelperText>{
                            x.maximumDays 
                                ? `${translate('stays of')} ${x.minimumDays} - ${x.maximumDays} ${translate('days')}` 
                                : x.minimumDays 
                                    ? `${translate('stays of')} ${x.minimumDays} ${translate('or more')}`
                                    : ''
                        }</FormHelperText>
                    </FormControl>)
                }
            </div>
            <div style={{textAlign: 'center'}}>
                <Button variant="contained" color="secondary" onClick={saveDiscounts}>{translate('Save')}</Button>
            </div>
        </div>
    );
};

Discounts.propTypes = {
    discounts: propTypes.array,
    pid: propTypes.number
};

export default Discounts;
