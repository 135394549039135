import React, {useEffect, useState} from 'react';
import ReactCalendar from 'react-calendar';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import 'react-calendar/dist/Calendar.css';
import './calendar.css';
import {translate, blockDateRange, unblockDateRange} from '@yocasa/data-service';
import {reservationStatus} from '@yocasa/common';
import { CALENDAR, PROPERTY_RESERVATIONS } from '../../constants/routes';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { Link } from 'react-router-dom';

const BlockRange = ({blockedDays, range}) => {
    const [blockedRange, setBlockedRange] = useState( blockedDays.some(x =>  x.status === reservationStatus.BLOCKED &&  range.startDate >= x.startDate && range.endDate <= x.endDate));

    useEffect(()=> setBlockedRange(blockedDays.some(x =>  x.status === reservationStatus.BLOCKED &&  range.startDate >= x.startDate && range.endDate <= x.endDate)), [range]);

    const onBlockedRangeChange = (event) => {
        setBlockedRange(event.target.checked);
        event.target.checked ? blockDateRange(range) : unblockDateRange(range);
    };
    
    return (
        <div className="block-range">
            <div>
                {`${moment(range.startDate).format('ll')} - ${moment(range.endDate).format('ll')}`}    
            </div>
            <FormGroup>
                <FormControlLabel 
                    control={<Checkbox checked={blockedRange} onChange={onBlockedRangeChange} />}
                    label={translate('Blocked')}
                />
            </FormGroup>
        </div>
    );
};
BlockRange.propTypes = {
    range: propTypes.object,
    blockedDays: propTypes.array
};

const Calendar = ({blockedDays, dispatch, history}) => {
    const now = new Date();
    const [value, onChange] = useState([now, now]);
    const [isBlockedRange, setIsBlockedRange] = useState(false);

    useEffect(() => { 
        history && dispatch({
            type: 'SET_PAGE_TITLE',
            pageTitle: translate('Calendar'),
            pageRoute: CALENDAR
        }); 
    }, [blockedDays, isBlockedRange]);
   
    const handleChange = (value) => {
        const dateValue = value.length === 2 ? value[1].valueOf() : value[0].valueOf();
        setIsBlockedRange(false);
        for (let i=0; i< blockedDays.length; i++) {
            if ( (blockedDays[i].status === reservationStatus.BLOCKED && dateValue >= blockedDays[i].startDate && dateValue <= blockedDays[i].endDate) ||
            (blockedDays[i].status === reservationStatus.BLOCKED && value[0].valueOf() >= blockedDays[i].startDate && value[0].valueOf() <= blockedDays[i].endDate)) {
                value[0] = new Date(blockedDays[i].startDate);
                value[1] = new Date(blockedDays[i].endDate);
                setIsBlockedRange(true);
                break;
            }
        }
        onChange(value);
    };

    const isTileDisabled = ({date, view}) =>
    { 
        if (view === 'month') {
            const dateValue = date.valueOf();
            for (const range of blockedDays){
                if (range.status !== reservationStatus.CANCELED && range.status !== reservationStatus.BLOCKED && dateValue >= range.startDate && dateValue < range.endDate) {
                    return true;
                }
            }    
        }
        return false;
    }; 

    const setBackgroundColor = ({date, view}) => {
        if (view === 'month') {
            const dateValue = date.valueOf();
            for (const range of blockedDays){
                if (range.status === reservationStatus.PAID && dateValue >= range.startDate && dateValue <= range.endDate) {
                    return 'green-bg';
                }
                else if (range.status === reservationStatus.SCHEDULED && dateValue >= range.startDate && dateValue <= range.endDate && dateValue > now.valueOf()) {
                    return 'purple-bg';
                }
                else if (range.status === reservationStatus.SCHEDULED && dateValue >= range.startDate && dateValue <= range.endDate && dateValue <= now.valueOf()) {
                    return 'red-bg';
                }
                else if (range.status === reservationStatus.BLOCKED && dateValue >= range.startDate && dateValue <= range.endDate) {
                    return 'grey-bg';
                }
            }    
        }
    };

    const reservationLink = (dateValue, text, title) => (
        <Link 
            className="reservation-link" 
            title={title}
            to={{
                pathname: PROPERTY_RESERVATIONS,
                search:`?startDate=${dateValue}`
            }}>
            {text}
        </Link>
    );

    const setCustomContent = ({date, view}) => {
        if (view === 'month') {
            const dateValue = date.valueOf();
            for (const range of blockedDays){
                if (range.status === reservationStatus.PAID && dateValue === range.startDate) {
                    return reservationLink(dateValue, 'P', 'Paid');
                }
                else if (range.status === reservationStatus.SCHEDULED && dateValue === range.startDate && dateValue > now.valueOf()) {
                    return reservationLink(dateValue, 'S', 'Scheduled');
                }
                else if (range.status === reservationStatus.SCHEDULED && dateValue === range.startDate && dateValue <= now.valueOf()) {
                    return reservationLink(dateValue, 'PD', 'Past Due');
                }
                else if (range.status === reservationStatus.BLOCKED && dateValue === range.startDate) {
                    return reservationLink(dateValue, 'B', 'Blocked');
                }
            }    
        }
    };

    return (
        <div className="calendar">
            <ReactCalendar 
                tileDisabled={isTileDisabled}
                onChange={handleChange}
                allowPartialRange={true}
                selectRange={true}
                tileClassName={setBackgroundColor}
                tileContent={setCustomContent}
                value={value}
            />
            <div className="color-coding text-font-x-small">
                <div className="purple-bg">{translate('Scheduled')}</div>
                <div className="green-bg">{translate('Paid')}</div>
                <div className="red-bg">{translate('Past Due')}</div>
                <div className="grey-bg">{translate('Blocked')}</div>
            </div>
            { (isBlockedRange ||  value.length === 2 && now.valueOf() <= value[1].valueOf() && value[0].valueOf() < value[1].valueOf()) 
                ? <BlockRange blockedDays={blockedDays} range={{startDate: value[0].valueOf(), endDate: value[1].valueOf()}} />
                : <div className="block-instructions text-font-small">
                    {translate('* Click on the start date and then click on the end date to block a range. To unblock click within the range.')}
                </div>
            }
        </div>
    );
};

Calendar.propTypes = {
    dispatch: propTypes.func,
    blockedDays: propTypes.array,
    history: propTypes.object
};
 
const mapStateToProps = (state) => ({
    blockedDays: state.property.blockedDays || []
});

export default connect(mapStateToProps)(Calendar);

