import React from 'react';
import {translate} from '@yocasa/data-service';
import propTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


import './pictures.css';

const CaptionInput = ({cancel, open, save, tag, caption}) => {
    const [_tag, setTag] = React.useState(tag);
    const [_caption, setCaption] = React.useState(caption);

    const handleClose = (accepted) => {
        accepted ? save(_caption, _tag) : cancel();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{translate('Photo Details')}</DialogTitle>
            <DialogContent className="caption-container">
                <FormControl fullWidth>
                    <TextField value={_caption} onChange={(e)=> setCaption(e.target.value)} label={translate('Photo Caption')} placeholder={translate('please type (caption is optional)')} fullWidth/>
                </FormControl>
                <FormControl className="location">
                    <InputLabel htmlFor="name-native-disabled">{translate('Photo Location')}</InputLabel>
                    <NativeSelect
                        value={_tag}
                        onChange={(e)=> setTag(e.target.value)}
                    >
                        <option value=""></option>
                        <optgroup label="Indoor">
                            <option value="Indoor Bathroom">{translate('Bathroom')}</option>
                            <option value="Indoor Bedroom">{translate('Bedroom')}</option>
                            <option value="Indoor Dining Room">{translate('Dining Room')}</option>
                            <option value="Indoor Kitchen">{translate('Kitchen')}</option>
                            <option value="Indoor Halway">{translate('Halway')}</option>
                            <option value="Indoor Living Room">{translate('Living Room')}</option>
                            <option value="Indoor Swimming Pool">{translate('Swimming Pool')}</option>
                        </optgroup>
                        <optgroup label="Outdoor">
                            <option value="Outdoor Back Yard">{translate('Back Yard')}</option>
                            <option value="Outdoor Balconi">{translate('Balconi')}</option>
                            <option value="Outdoor Beach">{translate('Beach')}</option>
                            <option value="Outdoor Building">{translate('Building')}</option>
                            <option value="Outdoor City">{translate('City')}</option>
                            <option value="Outdoor Front Yard">{translate('Front Yard')}</option>
                            <option value="Outdoor Mountain">{translate('Mountain')}</option>
                            <option value="Outdoor Swimming Pool">{translate('Swimming Pool')}</option>
                            <option value="Outdoor Town">{translate('Town')}</option>
                        </optgroup>
                        <optgroup label="View">
                            <option value="Beach View">{translate('Beach')}</option>
                            <option value="Landscape View">{translate('City Landscape')}</option>
                            <option value="Mountain View">{translate('Mountain')}</option>
                            <option value="Ocean View">{translate('Ocean')}</option>
                            <option value="Street View">{translate('Street')}</option>
                        </optgroup>
                    </NativeSelect>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => handleClose(false)}>{translate('Cancel')}</Button>
                <button className={'submit'} onClick={() => save(_caption, _tag)}>{translate('Save')}</button>
            </DialogActions>
        </Dialog>
    );

};
CaptionInput.propTypes = {
    cancel: propTypes.func,
    save: propTypes.func,
    open: propTypes.bool,
    tag: propTypes.string,
    caption: propTypes.string
};

export default CaptionInput;