import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import './Dates.css';
import {reservationStatus} from '@yocasa/common';
import {translate, setStartDate, setReservation} from '@yocasa/data-service';

const Dates = (props) => {
    const futureDate = (date, days) => date ? new Date(date.valueOf() + 86400*1000*days) : null;    
    const pastDate = (date, days) => date ? new Date(date.valueOf() - 86400*1000*days) : null;    
    const now = new Date();
    const daysBefore = 60;
    const daysAfter = 360;
    const minimumDays = 1;
    const firstEligibleDate = pastDate(now, daysBefore);

    const daysInRange = (startDate, endDate) => {
        const days = [];
        let date = startDate;

        while (date < endDate) {
            days.push(date);
            date = date + 86400*1000;
        }
        return days;    
    };

    const getDisabledDays = () => {
        const days = [];
        if (props.blockedDays) {
            // eslint-disable-next-line no-unused-vars
            for (const range of props.blockedDays){
                if (range.endDate >= firstEligibleDate.valueOf() && range.status !== reservationStatus.CANCELED) {
                    daysInRange(range.startDate, range.endDate).forEach(day => days.push(day));
                }
            }
        }
        return days;
    };
    const disabledDays = getDisabledDays();

    const handleCheckinChange = (date) => {
        props.dispatch(setStartDate(date));
        if (props.endDate)
            props.dispatch(setReservation({endDate: null, days: 0}));
    };

    const handleCheckoutChange = (date) => {
        if (props.startDate) {
            const days = Math.round((date - props.startDate)/86400000);
            props.dispatch(setReservation({endDate: date, days, absolutePrice: props.price * days}));
        }            
    };

 
    const DatesInput = ({value, placeholder, onClick}) => (
        <Button variant="contained" color="primary" className="date-input" onClick={onClick}>
            {value || placeholder}
        </Button>
    );
    DatesInput.propTypes = {
        value: propTypes.string,
        placeholder: propTypes.string,
        onClick: propTypes.func
    };

    return (
        <div>
            <Typography variant="caption" display="block" >{translate('Dates')}</Typography>
            <div className="check-in">
                <DatePicker
                    selected={props.startDate}
                    minDate={firstEligibleDate}
                    maxDate={futureDate(now, daysAfter)}
                    onChange={handleCheckinChange}
                    locale={props.languageCode}
                    title={translate('Date of Check-in')}
                    dateFormat="MMMM d, yyyy"
                    selectsStart
                    startDate={props.startDate}
                    endDate={props.endDate}      
                    placeholderText={translate('Date of Check-in')} 
                    disabledKeyboardNavigation
                    customInput={<DatesInput />}     
                    excludeDates={disabledDays}
                    isClearable
                />        
                <DatePicker
                    selected={props.endDate}
                    minDate={futureDate(props.startDate, minimumDays)}
                    maxDate={futureDate(props.startDate, daysAfter + minimumDays)}
                    onChange={handleCheckoutChange}
                    locale={props.languageCode}
                    title={translate('Date of Check-out')}
                    dateFormat="MMMM d, yyyy"
                    selectsEnd
                    endDate={props.endDate}
                    placeholderText={translate('Date of Check-out')}     
                    disabledKeyboardNavigation
                    customInput={<DatesInput />} 
                    excludeDates={disabledDays}
                    isClearable
                />        
            </div>
        </div> 
    );    
};

Dates.propTypes = {
    dispatch: propTypes.func.isRequired,
    startDate: propTypes.object,
    endDate: propTypes.object,
    blockedDays: propTypes.array,
    price: propTypes.number,
    languageCode: propTypes.string
};

const mapStateToProps = (state) => {
    const {startDate, endDate, price} = state.reservation;
    const {blockedDays, dataLanguage:languageCode} = state.property;
    return {
        startDate,
        endDate,
        price,
        blockedDays,
        languageCode
    };
};

export default connect(mapStateToProps)(Dates);
