import React from 'react';
import propTypes from 'prop-types';
import moment from 'moment';
import numeral from 'numeral';
import Button from '@material-ui/core/Button';
import './reservation-list-page.css';
import {INVOICE} from '../../constants/routes';
import {reservationStatus, getCurrencyPrice} from '@yocasa/common';
import {translate, updateReservationStatus, makeReservationPayment} from '@yocasa/data-service';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {connect} from 'react-redux';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import {currencySymbols} from '@yocasa/common';

const ShowReservation = ({reservation, navHistory, propertyId, index}) => {
    const [open, setOpen] = React.useState(false);
    const [paymentOpen, setPaymentOpen] = React.useState(false);
    const [payment, setPayment] = React.useState(Math.round((reservation.totalPrice - reservation.paymentMade)/100));

    const handleClickOpen = (dialog) => {
        dialog === 'cancel' ? setOpen(true) : setPaymentOpen(true);
    };
  
    const handleClose = (dialog) => {
        dialog === 'cancel' ? setOpen(false) : setPaymentOpen(false);
    };

    const startDate = moment(reservation.startDate).format('ll');
    const endDate = moment(reservation.endDate).format('ll');
    const nights = ` (${Math.round((reservation.endDate - reservation.startDate)/86400000)} ${translate('nights')})`;
    //const today = new Date().valueOf();
    let guests = `${reservation.guests.adults} ${translate('adults')}`;
    if (reservation.guests.children) {
        guests += `, ${reservation.guests.children} ${translate('children')}`;
    }
    if (reservation.guests.infants) {
        guests += `, ${reservation.guests.infants} ${translate('infants')}`;
    }
    const total = numeral(getCurrencyPrice(reservation.totalPrice, reservation.currency)/100).format('$0,0');
    const price = `${numeral(getCurrencyPrice(reservation.price, reservation.currency)/100).format('$0,0')} 
        (${reservation.guests.adults+reservation.guests.children} guests)`;
    const amountDue = numeral(getCurrencyPrice(reservation.totalPrice - reservation.paymentMade, reservation.currency)/100).format('$0,0');

    const cancelReservation = () => {
        updateReservationStatus(reservation, reservationStatus.CANCELED);
        handleClose('cancel');
    };

    const makePayment = () => {
        payment > 0 && makeReservationPayment(propertyId, reservation.ownerPhone, reservation.startDate, reservation.totalPrice, reservation.paymentMade, payment*100);
        handleClose('payment');
    };

    const cancelDialog = () => (
        <Dialog
            open={open}
            onClose={() => handleClose('cancel')}
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {translate(`Cancel your reservation for ${startDate}?`)}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose('cancel')} color="primary" autoFocus>
                    {translate('No')}
                </Button>
                <Button onClick={cancelReservation} color="secondary">
                    {translate('Yes')}
                </Button>
            </DialogActions>
        </Dialog>
    );

    const handleFocus = (e) => e.currentTarget.select();

    const handleChange = (event) => {
        let change = '';
        change = + event.target.value;
        if (change >=0) {
            setPayment(change);
        }
    };

    const paymentDialog = () => (
        <Dialog
            open={paymentOpen}
            onClose={() => handleClose('cancel')}
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <InputLabel className="title">{translate('Accept payment of')}</InputLabel>
                    <Input
                        name="payment"
                        onChange={handleChange}
                        onFocus={handleFocus}
                        value={payment}
                        inputProps={{size:9, autoFocus: 'autoFocus', autoComplete:'off'}}
                        startAdornment={<InputAdornment position="start">{currencySymbols[reservation.currency] || '$'}</InputAdornment>}
                        endAdornment={<InputAdornment position="end">.00</InputAdornment>}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose('payment')} color="primary" autoFocus>
                    {translate('Cancel')}
                </Button>
                <Button onClick={makePayment} color="secondary">
                    {translate('Accept Payment')}
                </Button>
            </DialogActions>
        </Dialog>
    );
 
    const printInvoice = () => {
        return navHistory({
            pathname: INVOICE,
            state: {
                reservation,
                amountDue: reservation.totalPrice - reservation.paymentMade,
                rid: reservation.startDate,
                alreadyPaid: reservation.paymentMade
            }
        });
    };

    const showPayment = reservation.totalPrice > reservation.paymentMade && reservation.status !== reservationStatus.CANCELED;
    const showCancel = reservation.status !== reservationStatus.CANCELED && (reservation.status !== reservationStatus.PAID || reservation.endDate > Date.now().valueOf()) ;
    const showInvoice = reservation.status !== reservationStatus.CANCELED;

    return (
        <div className={`reservation ${index%2 === 0 ? 'even' : 'odd'}`}>
            {cancelDialog()}
            {paymentDialog()}
            <div className="item">
                <span className="subtitle">{`${translate('Property')}: `}</span>
                <span className="value">{`${reservation.propertyName} - ${reservation.propertyLocation}`}</span>
            </div>
            <div className="item">
                <span className="subtitle">{`${translate('Dates')}: `}</span>
                <span className="value">{`${startDate} - ${endDate} ${nights}`}</span>
            </div>
            <div className="item">
                <span className="subtitle">{`${translate('Reservation Name')}: `}</span>
                <span className="value">{reservation.ownerName}</span>
            </div>
            <div className="item">
                <span className="subtitle">{`${translate('Reservation Phone')}: `}</span>
                <span className="value">{reservation.ownerPhone}</span>
            </div>
            <div className="item">
                <span className="subtitle">{`${translate('Guests')}: `}</span>
                <span className="value">{guests}</span>
            </div>
            <div className="item">
                <span className="subtitle">{`${translate('Price per Night')}: `}</span>
                <span className="value">{price}</span>
            </div>
            <div className="item">
                <span className="subtitle">{`${translate('Total Price')}: `}</span>
                <span className="value">{`${total} ${reservation.currency}`}</span>
            </div>
            {reservation.totalPrice > reservation.paymentMade &&
            <div className="item">
                <span className="subtitle">{`${translate('Amount due')}: `}</span>
                <span className="value">{`${amountDue} ${reservation.currency}`}</span>
            </div>
            }
            <div className="item">
                <span className="subtitle">{`${translate('Status')}: `}</span>
                <span className="status">{reservation.status === 'SCHEDULED' && reservation.startDate <= Date.now().valueOf() ? translate('Past Due') : translate(reservation.status)}</span>
            </div>
            <div className="buttons">
                {showPayment &&
                    <Button color="secondary" 
                        onClick={() => handleClickOpen('payment')}>
                        {translate('Accept payment')}
                    </Button>}
                {showInvoice &&
                    <Button color="primary" 
                        onClick={printInvoice}>
                        {reservation.status === reservationStatus.PAID ? translate('Print Receipt') : translate('Print invoice')}
                    </Button>
                }
                {showCancel &&
                    <Button color="secondary" onClick={() => handleClickOpen('cancel')}>{translate('Cancel reservation')}</Button>}
            </div>
            <hr/>
        </div>
    );
};

ShowReservation.propTypes = {
    reservation: propTypes.object.isRequired,
    navHistory: propTypes.func,
    propertyId: propTypes.number,
    index: propTypes.number
};

const mapStateToProps = (state) => ({
    propertyId: state.property.id
});

export default connect(mapStateToProps)(ShowReservation);
