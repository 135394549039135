import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import ReservationDetails from './components/Details';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import {currencySymbols} from '@yocasa/common';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import {translate, setReservation} from '@yocasa/data-service';
import TextField from '@material-ui/core/TextField';
import {FEES_PAGE, CONFIRMATION_PAGE} from './components/reservation-input-pages';

import './priceInput.css';

const DiscountsInput = ({gotoPage, reservation, dispatch}) => {
    const [addDiscounts, setAddDiscounts] = React.useState(reservation.addDiscounts || false);
    const [discounts] = React.useState(reservation.discounts || []);
    const [reRender, setReRender] = React.useState(false);

    const handleChange = (event, items, index) => {
        const name = event.target.name;
        let change = '';

        switch (name) {
            case 'item-type':
                change = event.target.value === 'true' || event.target.value === true;
                items[index].isAmount = change;
                items[index].value = '';
                break;
            case 'discount-name':
                change = event.target.value;
                items[index].name = change;
                break;
            case 'discount-value':
                change = + event.target.value;
                if (change >=0 && (items[index].isAmount || change <=100))
                    items[index].value = change;
                break;
            case 'add-items':
                change = event.target.checked;
                setAddDiscounts(change);
                if (change && items.length === 0) {
                    items.push({
                        name: '',
                        value: '',
                        isAmount: true
                    });
                } else if (!change) {
                    items.length = 0;
                }
                break;
        
            default:
        }
        if (index !== 'undefined') {
            setReRender(!reRender);
        }
    };

    const handleFocus = (e) => e.currentTarget.select();

    const itemsAmount = (items) => {
        let amount = 0;
        items.forEach(item => {
            amount += item.isAmount ? item.value : item.value * reservation.absolutePrice/100; 
        });
        return amount;
    };

    const submitInput = (event) => {
        event.preventDefault();
        dispatch(setReservation({
            discounts,
            totalPrice: reservation.absolutePrice + itemsAmount(reservation.fees) - itemsAmount(discounts),
            addDiscounts
        }));
        gotoPage(CONFIRMATION_PAGE);
    };

    const navButtons = () => (
        <div className="buttons">
            <Button
                onClick={()=> gotoPage(FEES_PAGE)} 
                variant="contained"
                className="reservation-reserve-button" >
                {translate('Previous')}
            </Button>
            <Button
                onClick={submitInput} 
                variant="contained" color="secondary"
                className="reservation-reserve-button" >
                {translate('Next')}
            </Button>
        </div>
    );

    const showItems = (items) => (
        <div>
            {items.map((item, index) => <div className={index%2 ? 'odd' : 'even'} key={index}>
                <TextField className="item-name" placeholder={translate('Name')} name="discount-name" value={item.name} onChange={(e) => handleChange(e, items, index)}/>
                <Input
                    className="item-value"
                    name="discount-value"
                    placeholder={item.isAmount ? 'Amount' : 'Percent'}
                    onChange={(e) => handleChange(e, items, index)}
                    onFocus={handleFocus}
                    value={item.value}
                    inputProps={{size:item.isAmount ? 6 : 8, autoComplete:'off'}}
                    startAdornment={<InputAdornment position="start">{item.isAmount && (currencySymbols[reservation.currency] || '$')}</InputAdornment>}
                    endAdornment={<InputAdornment position="end">{item.isAmount ? '.00' : '%'}</InputAdornment>}
                />
                <RadioGroup value={item.isAmount} name="item-type" className="item-type" onChange={(e) => handleChange(e, items, index)}>
                    <FormControlLabel value={true} control={<Radio />} label={translate('by amount')} />
                    <FormControlLabel value={false} control={<Radio />} label={translate('by percentage')} />
                </RadioGroup>                
            </div>)}
        </div>
    );

    const addItems = (e, items) => {
        items.push({
            name: '',
            value: '',
            isAmount: true
        });
        setReRender(!reRender);
    };

    return (
        <div className="page2-container">
            <ReservationDetails gotoPage={gotoPage}/>
            <div className="reservation-content">    
                <FormControlLabel 
                    className="add-items"
                    control={<Checkbox checked={addDiscounts} name="add-items" onChange={(e) => handleChange(e, discounts, 0)} />}
                    label={translate('Subtract Discounts')}
                />
                {addDiscounts && showItems(discounts)}
                {addDiscounts && <Button className="add-more-button" onClick={(e) => addItems(e, discounts)} variant="contained" title={translate('more discounts')} color="primary">+</Button>}
            </div>
            {navButtons()}
        </div>
    );

};
DiscountsInput.propTypes = {
    dispatch: propTypes.func,
    gotoPage:  propTypes.func,
    reservation: propTypes.object,
};




const mapStateToProps = (state) => {
    return({
        reservation: state.reservation
    });
};

export default connect(mapStateToProps)(DiscountsInput);
