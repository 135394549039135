import React from 'react';
import propTypes from 'prop-types';
import {translate, saveCurrentProperty} from '@yocasa/data-service';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import feeList from '../../../constants/feesList';
import {currencySymbols} from '@yocasa/common';
import Button from '@material-ui/core/Button';

import '../features.css';

const Fees = ({pid, fees, currencyLabel}) => {

    const showFees = (fees && fees.length > 0)
        ? fees.map(x => {x.amount = Math.round(x.amount/100); return x;})
        : feeList.map(x => {x.amount = Math.round(x.amount/100); return x;});
    const [_fees, setFees] = React.useState(showFees);

    const handleChange = (index, value) => {
        let change = '';

        change = + value;
        if (change >=0) {
            const fee = {..._fees[index]};
            fee.amount = value;
            const fa = [..._fees];
            fa.splice(index, 1, fee);
            setFees(fa);
        }
    };

    const saveFees = () => {
        saveCurrentProperty(pid, {
            fees: _fees.map(x => {x.amount = x.amount*100; return x;})
        });
    };

    return (
        <div className="pricing-container">
            <div className="prices">
                {_fees.map((x, index) => 
                    <FormControl key={x.id} className="price">
                        <InputLabel className="title">{translate(x.description)}</InputLabel>
                        <Input
                            inputProps={{size:9}}
                            startAdornment={<InputAdornment position="start">{currencySymbols[currencyLabel] || '$'}</InputAdornment>}
                            endAdornment={<InputAdornment position="end">.00</InputAdornment>}
                            onChange={(e)=>handleChange(index, e.target.value)}
                            value={x.amount} />
                        <FormHelperText>{translate('Amount')}</FormHelperText> 
                    </FormControl>
                )}
            </div>
            <div style={{textAlign: 'center'}}>
                <Button variant="contained" color="secondary" onClick={saveFees}>{translate('Save')}</Button>
            </div>
        </div>
    );
};

Fees.propTypes = {
    fees: propTypes.array,
    currencyLabel: propTypes.string,
    pid: propTypes.number
};

export default Fees;
