import React from 'react';
import ReactDOM from 'react-dom';
import 'numeral/locales/fr';
import './common/pt-cv';
import Main from './yocasa';
import './yocasa.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import swConfig from './serviceWorkerConfig';
//import reportWebVitals from './reportWebVitals';


ReactDOM.render(
    <Main/>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(swConfig);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
// reportWebVitals(console.log);
