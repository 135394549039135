export default [{
    name: 'Pets',
    number: 1,
    adminQuestion: 'are allowed',
    showGuests: 'pets are not allowed'
}, {
    name: 'Parties',
    number: 2,
    adminQuestion: 'are allowed',
    showGuests: 'parties are not allowed'
}, {
    name: 'Smoking',
    number: 3,
    adminQuestion: 'is allowed',
    showGuests: 'smoking is not allowed'
}, {
    name: 'Fucking',
    number: 4,
    adminQuestion: 'is allowed',
    showGuests: 'fucking is not allowed'
}
];