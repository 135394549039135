import React, {useEffect} from 'react';
import propTypes from 'prop-types';
import {connect} from 'react-redux';
import { PROPERTY_RESERVATIONS } from '../../constants/routes';
import {translate} from '@yocasa/data-service';
import {getCurrencyPrice} from '@yocasa/common';
import ShowReservation from './ShowReservation';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import numeral from 'numeral';
import './reservation-list-page.css';

const PropertyReservations = ({reservations, history, dispatch, current}) => {
    const [sinceFilter, setSinceFilter] = React.useState('ALL');
    const [statusFilter, setStatusFilter] = React.useState('All');
    const [total, resetTotal] = React.useState({});

    const sinceOptions = {
        ALL: translate('All'),
        YTD: translate('Year to date'),
        LASTYEAR: translate('Last year'),
        MONTH: translate('Current month'),
        TODAY: translate('Today')
    };
    const reservationOptions = ['All', 'Paid', 'Scheduled', 'Canceled'];

    useEffect(() => { 
        dispatch({
            type: 'SET_PAGE_TITLE',            
            pageTitle: 'Property Reservations',
            pageRoute: PROPERTY_RESERVATIONS
        }); 
        resetTotal({});
    }, [reservations]);

    const showReservations = () => {
        const now = new Date();
        const statusChosen = statusFilter.toUpperCase();
        let from = 0;
        let to = 0;
        switch (sinceFilter) {
            case 'YTD':
                from = new Date(now.getFullYear() -1, 11, 31).valueOf();
                break;
            case 'LASTYEAR':
                from = new Date(now.getFullYear() -1, 0, 1).valueOf();
                to = new Date(now.getFullYear() -1, 11, 31).valueOf();
                break;
            case 'MONTH':
                from = new Date(now.getFullYear(), now.getMonth() ? now.getMonth()-1 : 0, 1).valueOf();
                break;
            case 'TODAY':
                from = now.valueOf() - 86400000;
                break;
        }

        return (
            <div>
                <div className="reservations">
                    {
                        to ? reservations.map((reservation, index) => {
                            if ((!from || reservation.endDate >= from) && (statusChosen === 'ALL' || statusChosen === reservation.status)) {
                                reservation.currency in total ? total[reservation.currency] += reservation.paymentMade : total[reservation.currency] = reservation.paymentMade;
                                return <ShowReservation key={index} index={index} reservation={reservation}  navHistory={history.push} />;
                            }
                        }) : reservations.map((reservation, index) => {
                            if ((!from || (reservation.endDate >= from && reservation.endDate <= to)) && (statusChosen === 'ALL' || statusChosen === reservation.status)) {
                                reservation.currency in total ? total[reservation.currency] += reservation.paymentMade : total[reservation.currency] = reservation.paymentMade;
                                return <ShowReservation key={index} index={index} reservation={reservation}  navHistory={history.push} />;
                            }
                        })
                    }
                </div>
            </div>
        );
    };

    const showTotal = () => (
        <div>
            <label style={{backgroundColor: 'lightgreen'}}>Total payments: </label>
            {Object.keys(total).map(currency => <div key={currency}>{`${currency} : ${numeral(getCurrencyPrice(total[currency], currency)/100).format('$0,0')}`}</div>)}
        </div>
    );

    const showReservation = (startDate) => {
        const reservation = reservations.filter(item => item.startDate === +startDate)[0];
        return reservation 
            ? <ShowReservation index={0} reservation={reservation}  navHistory={history.push}/>
            : 'Reservation not found!';
    };
    
    return (
        <div className="reservation-list">
            <span className="title">{`${translate('Reservations for')} ${current.name}`}</span>
            { window.location.search.includes('startDate') 
                ? showReservation(window.location.search.substr(11))
                : <div>
                    <div className="filter">
                        <label style={{fontWeight: '700', color: 'red', marginRight: '10px'}}>{translate('Since:')}</label>
                        <Select
                            style={{minWidth:'112px'}}
                            value={sinceFilter}
                            onChange={(e)=> setSinceFilter(e.target.value)}
                        >
                            {Object.keys(sinceOptions).map(item => <MenuItem key={item} value={item}>{sinceOptions[item]}</MenuItem>)}
                        </Select>
                        <label style={{fontWeight: '700', color: 'red', marginRight: '10px', marginLeft: '20px'}}>{translate('Status:')}</label>
                        <Select
                            style={{minWidth:'105px'}}
                            value={statusFilter}
                            onChange={(e)=> setStatusFilter(e.target.value)}
                        >
                            {reservationOptions.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
                        </Select>        
                    </div>
                    {reservations && reservations.length > 0
                        ?
                        showReservations()
                        :
                        <span className="no-reservation">{translate('No reservations yet')}</span>
                    }
                    {
                        Object.keys(total).length > 0 && showTotal()
                    }
                </div>
            }
        </div>
    );
};

PropertyReservations.propTypes = {
    reservations: propTypes.array,
    history: propTypes.object.isRequired,
    dispatch: propTypes.func.isRequired,
    current: propTypes.object
};
 
const mapStateToProps = (state) => ({
    reservations: state.property.reservations,
    current: state.property
});

export default connect(mapStateToProps)(PropertyReservations);
