import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import ReservationDetails from './components/Details';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import {currencySymbols} from '@yocasa/common';
import Button from '@material-ui/core/Button';
import {FEES_PAGE, DETAILS_PAGE} from './components/reservation-input-pages';
import {translate, setReservation} from '@yocasa/data-service';

const PriceInput = ({gotoPage, reservation, dispatch}) => {
    const [absolutePrice, setAbsolutePrice] = React.useState(reservation.price * reservation.days);
    const [price, setPrice] = React.useState(reservation.price);
    const [pricePerNight, setPricePerNight] = React.useState(reservation.pricePerNight || true); 

    const handleChange = (event) => {
        const name = event.target.name;
        let change = '';

        switch (name) {
            case 'pricePerNight':
                change = event.target.value === 'true' || event.target.value === true;
                setPricePerNight(change);
                dispatch(setReservation({pricePerNight: change}));
                break;
            case 'price':
                change = + event.target.value;
                if (change >=0) {
                    if (pricePerNight) {
                        setPrice(change);
                        dispatch(setReservation({price: change}));
                        setAbsolutePrice(Math.round(change * reservation.days));
                        dispatch(setReservation({absolutePrice: Math.round(change * reservation.days)}));
                    }  else {
                        setAbsolutePrice(change);
                        dispatch(setReservation({Absoluteprice: change}));
                        setPrice(Math.round(change / reservation.days));
                        dispatch(setReservation({price: Math.round(change / reservation.days)}));
                    } 
                }
                break;
            default:
        }
    };

    const handleFocus = (e) => e.currentTarget.select();

    const submitInput = (event) => {
        event.preventDefault();
        gotoPage(FEES_PAGE);
    };

    const navButtons = () => (
        <div className="buttons">
            <Button
                onClick={()=> gotoPage(DETAILS_PAGE)} 
                variant="contained"
                className="reservation-reserve-button" >
                {translate('Previous')}
            </Button>
            <Button
                onClick={submitInput} 
                variant="contained" color="secondary"
                disabled={price === 0} 
                className="reservation-reserve-button" >
                {translate('Next')}
            </Button>
        </div>
    );

    return (
        <div className="page2-container">
            <ReservationDetails gotoPage={gotoPage}/>
            <div className="reservation-content">
                <div className="price-input">
                    <InputLabel className="title">{translate('Reservation Price')}</InputLabel>
                    <Input
                        name="price"
                        onChange={handleChange}
                        onFocus={handleFocus}
                        value={pricePerNight ? price: absolutePrice}
                        inputProps={{size:9, autoFocus: 'autoFocus', autoComplete:'off'}}
                        startAdornment={<InputAdornment position="start">{currencySymbols[reservation.currency] || '$'}</InputAdornment>}
                        endAdornment={<InputAdornment position="end">.00</InputAdornment>}
                    />
                </div>
                <RadioGroup value={pricePerNight} name="pricePerNight" className="item-type" onChange={handleChange}>
                    <FormControlLabel value={true} control={<Radio />} label={translate('Price per night')} />
                    <FormControlLabel value={false} control={<Radio />} label={translate('Total price')} />
                </RadioGroup>            
            </div>
            {navButtons()}

        </div>
    );

};
PriceInput.propTypes = {
    dispatch: propTypes.func,
    gotoPage:  propTypes.func,
    reservation: propTypes.object,
};




const mapStateToProps = (state) => {
    return({
        reservation: state.reservation
    });
};

export default connect(mapStateToProps)(PriceInput);
