import React from 'react';
import './LoginForm.css';
import propTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import {translate, getCountry} from '@yocasa/data-service';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {validateEmail} from '@yocasa/common';
import {connect} from 'react-redux';

const initialState = {
    firstName: '', 
    lastName: '',
    email: '',
    phone: '',
    password1: '',
    password2: '',
    invalidEmail: false,
    countryCode: '',
    country: '',
    number: ''
};

class CreateUserForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
    }

    static propTypes = {
        signup: propTypes.func.isRequired,
        toggleForm: propTypes.func.isRequired,
        error: propTypes.object,
        message: propTypes.string,
        countryCode: propTypes.string
    }
    
    toggleForm = (action) => {
        this.setState({password1: '', password2: ''});
        this.props.toggleForm(action);
    }

    handleInput = (e) => {
        const field = e.currentTarget.dataset.field;
        const value = e.currentTarget.value;

        if (field === 'email') {
            this.setState({
                invalidEmail: value.length ? !validateEmail(value) : false
            });
        }
        e.preventDefault();
        this.setState({
            [field]: value
        });
    }

    componentWillUnmount() {
        this.setState(initialState);
    }

    phoneInputHandler = (value, country, e, formattedValue) => {
        this.setState({phone: formattedValue});
        this.setState({number: value});
        this.setState({country: country.name});
        this.setState({countryCode: country.countryCode});
        e.preventDefault();
    }

    register = () => {
        const isRegisterInvalid =
            this.state.password1 !== this.state.password2 ||
            this.state.password1.length < 6 ||
            this.state.email === '' || this.state.invalidEmail || this.state.phone === '' ||
            this.state.lastName === '' ||
            this.state.firstName === '';

        const countryCode = this.props.countryCode;

        return (
            <form name="register" className="register">
                <input type="text" value={this.state.firstName} data-field="firstName"
                    name="firstName" onChange={this.handleInput} placeholder={translate('first name')}/>
                <input type="text" value={this.state.lastName} data-field="lastName" 
                    name="lastName" onChange={this.handleInput}  placeholder={translate('last name')}/>
                <input required type="email" value={this.state.email} data-field="email" 
                    name="email" onChange={this.handleInput}  placeholder={translate('email address')}/>
                <span>{translate('phone')}</span>    
                <PhoneInput
                    placeholder={translate('phone number')}
                    value={ this.state.phone }
                    inputProps={{
                        type: 'tel',
                        required: true,
                        style:{width: '100%'}
                    }}
                    autoFormat={false}      
                    onChange={this.phoneInputHandler}
                    country={countryCode || 'cv'}
                />
                <input type="password" value={this.state.password1} data-field="password1" 
                    name="password2" onChange={this.handleInput}  placeholder={translate('password')}/>
                <input type="password" value={this.state.password2} data-field="password2" 
                    name="password2" onChange={this.handleInput}  placeholder={translate('repeat password')}/>
                <button className={'submit ' + (isRegisterInvalid ? 'disabled' : '')} 
                    disabled = {isRegisterInvalid}
                    onClick={(e) => {e.preventDefault(); this.props.signup(this.state); this.toggleForm('Login');}}>create</button>
                <p className="message">{translate('Already registered?')} 
                    <Link
                        className="link"
                        component="button"
                        variant="body2"
                        onClick={(e) => { e.preventDefault(); this.toggleForm('Login'); }}
                    >{translate('Login')}
                    </Link>
                </p>
                {this.props.error && 
                    <p className="error">{
                        this.props.error.message ? this.props.error.message : translate('Unknown Error')
                    }</p>
                }
            </form>
        );
    }


    render() {
        return ( 
            <div className="login-page">
                <div className="form">
                    { this.register()}
                </div>
            </div>
        );    
    }
}

const mapStateToProps = (state) => {
    const {countryCode} = state.settings;
    if (!countryCode) getCountry();

    return({
        countryCode: countryCode || null
    });
};

export default connect(mapStateToProps)(CreateUserForm);
