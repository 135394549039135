export const HOME = '/';
export const SIGN_IN = '/login';
export const SIGN_OUT = '/logout';
export const CALENDAR = '/calendar';
export const PASSWORD_FORGET = '/pw-forget';
export const USER_PROFILE = '/profile';
export const CREATE_RESERVATION = '/create-reservation';
export const INVOICE = '/show-invoice';
export const PROPERTY_RESERVATIONS = '/property-reservations';
export const EDIT_PICTURES = '/upload-pictures';
export const PROPERTY_BASICS = '/property-basics';
export const PROPERTY_SPACES = '/property-spaces';
export const PROPERTY_AMENITIES = '/property-ameneties';
export const PROPERTY_RULES = '/property-rules';
export const PROPERTY_PRICING = '/property-pricing';
export const PROPERTY_PHOTOS = '/property-photos';
export const ADMIN = '/admin-page';