import React, {useState, useEffect} from 'react';
import propTypes from 'prop-types';
import Gallery from 'react-photo-gallery';
import arrayMove from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddIcon from '@material-ui/icons/Add';
import {translate, deletePropertyImage, propertySetPhotoOrder} from '@yocasa/data-service';
import { EDIT_PICTURES} from '../../constants/routes';
import {withRouter} from 'react-router-dom';
import Spinner from '../utils/SpinnerModal';
import {connect} from 'react-redux';
import ConfirmDialog from '../utils/ConfirmDialog';
import './photos.css';

let _history = {};
let _from = '';
let _pid = 0;

const Photo = ({photo, margin}) => {
    const [_delete, setDelete] = React.useState(false);

    const editPhoto = () => {
        _history.push({
            pathname: EDIT_PICTURES,
            state: {
                from: _from,
                edit: true,
                asUrl: photo.src,
                src: {name: photo.filename},
                imageHeight: photo.height,
                imageWidth: photo.width,
                caption: photo.caption,
                tag: photo.tag
            }
        });
    };

    const deletePhoto = (confirmDelete) => {
        confirmDelete && deletePropertyImage({filename: photo.filename, pid: _pid});
    };

    return (
        <div className="photo">
            <img
                style={{margin: margin}}
                crossOrigin="anonymous"
                {...photo}
                alt={photo.tag}
            />
            <div className="top-right">
                <EditIcon className="edit-icon" onClick={editPhoto}/>
                <DeleteForeverIcon className="delete-icon" onClick={()=>setDelete(true)} />
            </div>
            {photo.tag && <div className="overlay">{translate(photo.tag.replace('Indoor ', '').replace('Outdoor ', ''))}</div>}
            <div className="caption" onClick={editPhoto}>
                {photo.caption 
                    ? <div className="text-font-small">{translate(photo.caption)}</div>
                    : <div className="link-style text-font-small">{translate('Edit Photo')}</div>
                }
            </div>
            <ConfirmDialog 
                title={translate('Delete Photo')}
                message={translate('Do you want to delete this photo?')}
                yesButton={translate('Yes')}
                noButton={translate('No')}
                status={_delete}
                callback={deletePhoto}
            />
        </div>
    );
};
Photo.propTypes = {
    photo: propTypes.object,
    margin: propTypes.number,
    direction: propTypes.string,
    top: propTypes.number,
    left: propTypes.number
};

const SortablePhoto = SortableElement(item => <Photo {...item} />);
const SortableGallery = SortableContainer(({ items }) => {
    useEffect(() => {
        propertySetPhotoOrder({photos: items, pid: _pid});
    }, [items]);

    return <Gallery photos={items} renderImage={props => <SortablePhoto {...props} />} />;
});

const Photos = ({photos, flag, history}) => {
    _history = history;
    const [items, setItems] = useState(photos);
    const [isLoading, setIsLoading] = React.useState(false);
    const [numberOfPics, setNumberOfPics] = useState(photos.length);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setItems(arrayMove(items, oldIndex, newIndex));
    };

    useEffect(() => {
        setItems(photos);
        if (photos.length > numberOfPics) {
            setIsLoading(false);
            setNumberOfPics(photos.length);
        }
    }, [photos]);

    useEffect(() => {
        if (flag) setIsLoading(true);
    }, [flag]);

    const getImages = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            const _URL = window.URL || window.webkitURL;
            const img = new Image();
            const objectUrl = _URL.createObjectURL(e.target.files[0]);

            reader.addEventListener('load', () => {
                setNumberOfPics(items.length);
                _history.push({
                    pathname: EDIT_PICTURES,
                    state: {
                        from: _from,
                        edit: false,
                        sortOrder: items.length ? items[items.length-1].order + 1 : 0,
                        asUrl: reader.result,
                        src: document.getElementById('myImage').files[0],
                        imageWidth: img.width > img.height ? 4 : img.width < img.height ? 3 : 1,
                        imageHeight: img.width > img.height ? 3 : img.width < img.height ? 4 : 1         
                    }
                });
            });
            reader.readAsDataURL(e.target.files[0]);
            img.src = objectUrl;
            img.onload = function() {
                URL.revokeObjectURL(this.src);
            };
        }
    };

    return (
        <div className="photo-container">
            <div className="add-photo-button">
                {photos.length > 1 && <div className="text-font-x-small">
                    {`${photos.length} ${translate('photos')}`}
                </div>}
                <label htmlFor="myImage">
                    <AddIcon className="add-icon" fontSize="large" />
                    {photos.length >0 ? translate('Add More') : translate('Add Photo')}
                </label>
                <input
                    className="hidden"
                    type="file"
                    accept="image/*"
                    id="myImage"
                    onChange={getImages}>
                </input>
            </div>
            {photos.length > 0 && 
                <div>
                    <div className="description">
                        <div className="cover">{translate('Cover Photo')}</div>
                        <div>{translate('Drag and Drop')}</div>
                    </div>
                    <SortableGallery items={items} onSortEnd={onSortEnd} axis={'xy'} pressDelay={150} />
                </div>
            }
            <Spinner isOpen={isLoading} />
        </div>
    );
};
Photos.propTypes = {
    photos: propTypes.array,
    flag: propTypes.bool,
    history: propTypes.object
};

const mapStateToProps = (state, ownProps) => {
    const {images, id, flag} = state.property;
    _pid = id;
    _from = ownProps.from;

    const getPhoto = (image) => {
        const mime = image ? image.filename.substr(image.filename.lastIndexOf('.')) : null;

        return ({
            src: image.url,
            width: image.width>image.height ? 4 : image.width<image.height ? 3 : 1,
            height: image.width>image.height ? 3: image.width<image.height ? 4 : 1,
            caption: image.caption ? image.caption : '',
            tag: image.tag ? image.tag : '',
            filename: image.filename,
            order: image.sortOrder || 0,
            srcSet: [
                `${image.url.replace(mime, '_500x500'+mime)} 500w`,
                `${image.url.replace(mime, '_700x700'+mime)} 700w`,
                `${image.url.replace(mime, '_900x900'+mime)} 900w`,
                `${image.url} ${image.width}w`,
            ],
            sizes: ['(min-width: 580px) 50vw,(min-width: 780px) 33.3vw,100vw']          
        });
    };

    const photos =  images
        ? images.map(x => getPhoto(x))
        : [];
    return {
        photos,
        flag
    };
};

export default connect(mapStateToProps)(withRouter(Photos));