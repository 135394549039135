import React from 'react';
import {connect} from 'react-redux';
import propTypes from 'prop-types';
import {Route, Redirect} from 'react-router-dom';
import {SIGN_IN} from '../../constants/routes';

const PrivateRoute = ({isAuthenticated, roles, ...rest}) => {
    return (
        isAuthenticated && 'admin' in roles
            ? <Route exact {...rest}/>
            : <Redirect to={SIGN_IN} />
    );};

PrivateRoute.propTypes = {
    isAuthenticated: propTypes.bool,
    roles: propTypes.object
};

const mapStateToProps = (state) => {
    return ({
        isAuthenticated: state.user.isAuthenticated,
        roles: state.user.roles
    });};

export default connect(mapStateToProps)(PrivateRoute);
