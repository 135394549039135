import React from 'react';
import Add from '@material-ui/icons/AddCircleOutline';
import Remove from '@material-ui/icons/RemoveCircleOutline';
import propTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import {translate} from '@yocasa/data-service';
import './guest-line.css';

const GuestLine = (props) => {
    return (
        <div className="guestline-container">
            <div 
                className="text-font-big guest-label">{ translate(props.label) }
                <sub 
                    className="text-font-x-small guest-label-info">{props.info}
                </sub>
            </div>
            <IconButton
                aria-label="discount information"
                aria-controls="discount-info"
                aria-haspopup="true"
                data-guesttype={props.label}
                onClick={props.decreaseNumberOfGuests}
                color="inherit"
            >
                <Remove fontSize="large" />   
            </IconButton>             
            <div>{ props.value }</div>
            <IconButton
                aria-label="discount information"
                aria-controls="discount-info"
                aria-haspopup="true"
                data-guesttype={props.label}
                onClick={props.increaseNumberOfGuests}
                color="inherit"
            >
                <Add fontSize="large" />
            </IconButton>
        </div>
    );
};

GuestLine.propTypes = {
    label: propTypes.string.isRequired,
    value: propTypes.number.isRequired,
    info: propTypes.string,
    increaseNumberOfGuests: propTypes.func.isRequired,
    decreaseNumberOfGuests: propTypes.func.isRequired
};

export default GuestLine;