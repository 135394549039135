import React, {useEffect} from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import {translate} from '@yocasa/data-service';
import numeral from 'numeral';
import {getCurrencyPrice, reservationStatus} from '@yocasa/common';
import {INVOICE} from '../../constants/routes';
import './ShowInvoice.css';

const ShowInvoice = ({current, reservation, dispatch}) => {

    const alreadyPaid = reservation.status === reservationStatus.PAID;

    useEffect(() => { 
        dispatch({
            type: 'SET_PAGE_TITLE',
            pageTitle: alreadyPaid ? translate('Receipt') : translate('Invoice'),
            pageRoute: INVOICE
        }); 
        window.setTimeout(() => {
            window.print();
        }, 500);
        // window.onafterprint = () => window.location.assign(window.location.origin); 
    }, [dispatch, alreadyPaid]);

    const printLineItem = (item, action) => (
        <div key={item.description} className="invoice-fees flex-column-spacing">
            <div>{action}</div>
            <div>{`${item.description}: `}</div>
            <div>{` ${numeral(getCurrencyPrice(item.amount, reservation.currency)/100).format('$0,0')} ${reservation.currency}`}</div>
        </div>
    );

    const numberOfGuests = reservation.guests.adults + reservation.guests.children + reservation.guests.infants;
    const numberOfDays = reservation.days;
    // eslint-disable-next-line no-undef
    const env = process.env;

    const paymentOptions = () => (
        <div>
            <div className="invoice-bank-info">
                <div className="bold">{translate('Please pay by direct transfer to bank account:')}</div>
                <div><span>{translate('Bank Name:')}</span><span>{env.REACT_APP_BN}</span></div>
                <div><span>{translate('Account Name:')}</span><span>{env.REACT_APP_ANM}</span></div>
                <div><span>{translate('Account Number:')}</span><span>{env.REACT_APP_ANB}</span></div>
                <div><span>{translate('IBAN:')}</span><span>{env.REACT_APP_IB}</span></div>
                <div><span>{translate('NIB :')}</span><span>{env.REACT_APP_NIB}</span></div>
                <div><span>{translate('SWIFT/BIC:')}</span><span>{env.REACT_APP_SB}</span></div>
            </div>
            <div className="invoice-credit-card">
                <div className="bold">{translate('Or pay by credit card on our secure site:')}</div>
                <div><a href={env.REACT_WEB_APP_URL}>{env.REACT_APP_WEB_URL}</a></div>
            </div>
            <div className="invoice-pay-cash bold">
                {`${translate('Please pay before checkin date of')} ${moment(reservation.startDate).format('ll')}`}
            </div>
        </div>
    );

    const header = () => (
        <div>
            <header className="invoice-header bold">
                <div>{current.name}</div>
                <div>{current.address.city}, {current.address.state}</div>
                <div>{current.address.country}</div>
                <div>{`Tel: ${current.contact.phone}`}</div>
                <div className="invoice-contact flex-column-spacing">
                    <span>{`App: ${env.REACT_APP_WEB_URL}`}</span>
                    <span>{`Email: ${current.contact.email}`}</span>
                </div>
            </header>
        </div>
    );

    const footer = () => (
        <div>
            <footer className="invoice-footer bold">
                <div>{reservation.propertyName}</div>
                <div>{`Tel: ${current.contact.phone}`}</div>
                <div className="invoice-contact flex-column-spacing">
                    <span>{`App: ${env.REACT_APP_WEB_URL}`}</span>
                    <span>{`Email: ${current.contact.email}`}</span>
                </div>
            </footer>
        </div>
    );

    const discountsAndFees = (items, action) => (
        items 
            ? items.map(item => printLineItem(item, action))
            : null
    );

    const payments = () => {
        const say = alreadyPaid ? translate('Payment made on ') : translate('Total due on ');

        return (
            <div>
                <div className="invoice-total flex-column-spacing bold">
                    <span>{`${say} ${moment(reservation.startDate).format('ll')}:`}</span>
                    <span>{`${numeral(getCurrencyPrice(reservation.totalPrice, reservation.currency)/100).format('$0,0')} ${reservation.currency}`}</span>
                </div>
                {alreadyPaid ? <div className="paid-in-full">{translate('PAID IN FULL')}</div> : paymentOptions()}
            </div>
        );
    };

    return ( 
        <div className="invoice-container">
            {header()}
            <div className="invoice-date-line flex-column-spacing bold">
                <span className="invoice-date">{moment(reservation.dateCreated).format('ll')}</span>
                <span className="invoice-number">{`${alreadyPaid? translate('Receipt Number') : translate('Invoice number:')} ${reservation.startDate}`}</span>
            </div>
            <div className="invoice-guest-info bold">
                <div>{`${numberOfGuests} ${translate('Guests:')} ${reservation.ownerName} ${translate('and Companions')}`}</div>
                <div>{`${translate('Email:')} ${reservation.ownerEmail}`}</div>
                <div>{`${translate('Phone:')} ${reservation.ownerPhone}`}</div>
                <div className="bold">{`${translate('Reservation number:')} ${reservation.number} (${reservation.app})`}</div>
            </div>
            <div className="invoice-reservation-dates">
                <div>{`${numberOfDays} ${translate('night rental')}`}</div>
                <div>{`${translate('Arriving on')} ${moment(reservation.startDate).format('ll')}`}</div>
                <div>{`${translate('Departing on')} ${moment(reservation.endDate).format('ll')}`}</div>
            </div>
            <div className="invoice-subtotal flex-column-spacing">
                <span>{`${translate('nightly price:')} ${numeral(getCurrencyPrice(reservation.price, reservation.currency)/100).format('$0,0')} ${reservation.currency}`}</span>
                <span>{`${numberOfGuests} ${translate('Guests')}`}</span>
                <span>{`${numberOfDays} ${translate('night price:')} ${numeral(getCurrencyPrice(reservation.absolutePrice, reservation.currency)/100).format('$0,0')} ${reservation.currency}`}</span>
            </div>
            {discountsAndFees(reservation.discounts, translate('Subtract'))}
            {discountsAndFees(reservation.fees, translate('Add'))}
            {payments()}
            {footer()}
        </div>
    );
};

ShowInvoice.propTypes = {
    reservation: propTypes.object,
    current: propTypes.object,
    dispatch: propTypes.func
};

const mapStateToProps = (state, ownProps) => {
    const reservation = ownProps.location.state ? ownProps.location.state.reservation : state.reservation;

    return {
        current: state.property,
        reservation
    };
};

export default connect(mapStateToProps)(ShowInvoice);
