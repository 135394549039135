export default [{
    id: 'F_Cleaning',
    description: 'Cleaning Fee',
    info: '',
    amount: 200,
    per: 'stay',
    startDate: null,
    endDate: null
}, {
    id: 'F_LocalTaxes',
    description: 'Local Taxes',
    info: '',
    percent: 0,
    per: 'stay',
    startDate: null,
    endDate: null
}];