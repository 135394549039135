import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import propTypes from 'prop-types';

export default function AlertDialog({title, message, yesButton, noButton, callback, status}) {
    const [open, setOpen] = React.useState(status);

    const handleClose = (accepted) => {
        setOpen(false);
        callback(accepted);
    };

    React.useEffect(() => setOpen(status), [status]);

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>handleClose(false)} color="primary">{noButton}</Button>
                    <Button onClick={()=>handleClose(true)} color="primary" autoFocus>{yesButton}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
AlertDialog.propTypes = {
    title: propTypes.string.isRequired,
    message: propTypes.string.isRequired,
    yesButton: propTypes.string.isRequired,
    noButton: propTypes.string.isRequired,
    callback: propTypes.func.isRequired,
    status: propTypes.bool.isRequired
};
