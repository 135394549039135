import React from 'react';
import propTypes from 'prop-types';
import {translate, saveCurrentProperty} from '@yocasa/data-service';
import {currencies} from '@yocasa/common';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import '../features.css';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 250,
        marginBottom: 30
    }
}));

const SupportedCurrencies = ({pid, supportedCurrencies, defaultCurrency}) => {
    const [_supportedCurrencies] = React.useState(supportedCurrencies);
    const [_defaultCurrency, setDefaultCurrency] = React.useState(defaultCurrency);
    const classes = useStyles();

    const handleChange = (event) => {
        if (event.target.checked) {
            setDefaultCurrency(event.target.name);
            saveCurrentProperty(pid, {
                defaultCurrency: event.target.name
            });
        }
    };

    const removeCurrency = (e) => {
        delete _supportedCurrencies[e.target.id];
        saveCurrentProperty(pid, {
            supportedCurrencies: _supportedCurrencies
        });
    };

    const DisplayCurrency = ({label, index}) => {
        return (<div className={index%2 ? 'currency odd' : 'currency even'}>
            <div style={{minWidth: '100px'}}>{_supportedCurrencies[translate(label)]}</div>
            <FormControlLabel 
                control={<Checkbox name={label} checked={label === _defaultCurrency} onChange={handleChange} />}
                label={translate('Default')}
            />
            <DeleteForeverIcon id={label} className={label === _defaultCurrency ? 'non-delete-icon' : 'delete-icon'} onClick={removeCurrency} />
        </div>);
    };
    DisplayCurrency.propTypes = {
        label: propTypes.string,
        index: propTypes.number
    };

    const addCurrency = (e) => {
        _supportedCurrencies[e.target.value] = currencies[e.target.value];
        saveCurrentProperty(pid, {
            supportedCurrencies: _supportedCurrencies
        });
    };

    return (
        <div className="pricing-container">
            <div className="currencies">
                <span className="bold" style={{alignSelf: 'center'}}>{translate('Accepted Currencies')}</span>
                {Object.keys(_supportedCurrencies).map((x, index) => <DisplayCurrency label={x} index={index} key={index} />)}
            </div>
            <FormControl className={classes.formControl}>
                <InputLabel><span style={{whiteSpace: 'nowrap'}}>{translate('Accept other Currencies')}</span></InputLabel>
                <Select
                    value=""
                    onChange={addCurrency}
                    fullWidth>
                    <MenuItem value="" disabled>{translate('Accept other Currencies')}</MenuItem>    
                    {Object.keys(currencies).filter(item => !currencies[item].includes('('))
                        .map(item => item in _supportedCurrencies ? <MenuItem key={item} value={item} disabled>{currencies[item]}</MenuItem> : <MenuItem key={item} value={item}>{currencies[item]}</MenuItem>)}
                </Select>        
            </FormControl>
        </div>
    );
};

SupportedCurrencies.propTypes = {
    supportedCurrencies: propTypes.object,
    defaultCurrency: propTypes.string,
    pid: propTypes.number
};

export default SupportedCurrencies;
