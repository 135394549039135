import React from 'react';
import {connect} from 'react-redux';
import propTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import './Profile.css';
import unknownPerson from '../../images/unknown.png';
import AvatarEditor from 'react-avatar-editor';
import RotateLeftRoundedIcon from '@material-ui/icons/RotateLeftRounded';
import RotateRightRoundedIcon from '@material-ui/icons/RotateRightRounded';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { USER_PROFILE } from '../../constants/routes';
import {translate, avatarsRef, logError, updateUser} from '@yocasa/data-service';

class Profile  extends React.Component {
    constructor(props) {
        super(props);
        this.user = props.user;
        this.state = {
            phone: this.user.phone,
            country: this.user.country || null,
            number: '',
            countryCode: this.user.countryCode || null,
            profileImage: this.user.profileImage || null,
            rotate: 0
        };
    }

    componentDidMount() {
        this.props.dispatch({
            type: 'SET_PAGE_TITLE',
            pageTitle: translate('User Profile'),
            pageRoute: USER_PROFILE
        });
    }

    static propTypes = {
        user: propTypes.object.isRequired,
        dispatch: propTypes.func,
        history: propTypes.object
    }

    invalidInput = () => this.state.phone.length < 5

    setEditorRef = (editor) => this.editor = editor;

    cancel = () => {
        this.props.history.goBack();
    }

    save = () => {

        if (this.editor) {
            this.editor.getImageScaledToCanvas().toBlob((blob)=> {
                const fileName = `avatar-${this.user.uid}-${this.userFileName}`;
                const metadata = { 
                    contentType: this.userFileType,
                    cacheControl: 'public, max-age=604800, immutable'
                };   
                const task = avatarsRef()
                    .child(fileName)
                    .put(blob, metadata); 
        
                task
                    .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((url) => {
                        this.saveAvatar(url);
                    })
                    .catch((error) => {
                        logError({
                            date: new Date(),
                            code: 'profile.jsx, save',
                            type: 'Firebase Storage put API call',
                            details: error
                        });
                    });
        
    
            }, this.userFileType);
        } else {
            this.saveAvatar(this.state.profileImage); 
        }

        this.props.history.push('/');
    }

    saveAvatar = (avatar) => {        
        this.props.dispatch(updateUser(
            {
                phone: this.state.phone,
                avatar: avatar,
                country: this.state.country,
                countryCode: this.state.countryCode,
                profileImage: this.state.profileImage
            }
        ));
    }

    getProfileImageFile = () => {
        const file = document.getElementById('myImage').files[0];
        const fileName = `${this.user.uid}-${file.name}`;
        const metadata = { 
            contentType: file.type,
            cacheControl: 'public, max-age=604800, immutable' 
        };

        const task = avatarsRef().child(fileName).put(file, metadata); 

        task
            .then(snapshot => snapshot.ref.getDownloadURL())
            .then((url) => {
                this.image = url;
                this.setState({profileImage: url});
                this.userFileName = file.name;
                this.userFileType = file.type;
            })
            .catch((error) => {
                logError({
                    date: new Date(),
                    code: 'profile.jsx, save',
                    type: 'DataService Storage put API call',
                    details: error
                });
            });
    };

    avatar = () => {

        this.image = this.state.profileImage || unknownPerson;
        
        return ( 
            <div className="avatar-container">
                <AvatarEditor
                    ref={this.setEditorRef}
                    crossOrigin="anonymous"
                    image={this.image}
                    borderRadius={100}
                    width={250}
                    height={250}
                    border={15}
                    color={[255, 255, 255, 0.6]} // RGBA
                    scale={1.2}
                    rotate={this.state.rotate}
                />
                <div>
                    <RotateLeftRoundedIcon className="rotate" onClick={()=>this.setState({rotate: this.state.rotate-90})}/>
                    <RotateRightRoundedIcon className="rotate" onClick={()=>this.setState({rotate: this.state.rotate+90})}/>
                </div>
                <label 
                    htmlFor="myImage" 
                    className="avatar-button">
                    {
                        this.state.profileImage 
                            ? translate('Select a different picture') 
                            : translate('Add your picture')
                    }
                </label>
                <input 
                    className="avatar-input"
                    type="file" 
                    id="myImage" 
                    onChange={this.getProfileImageFile}>
                </input>
            </div>
        );
    }

    phoneInputHandler = (value, country, e, formattedValue) => {
        this.setState({phone: formattedValue});
        this.setState({number: value});
        this.setState({country: country.name});
        this.setState({countryCode: country.countryCode});
        e.preventDefault();
    }

    render() {
        return ( 
            <div>
                <form className="profile-container" noValidate autoComplete="off">
                    {this.avatar()} 
                    <Typography>{`${this.user.firstName} ${this.user.lastName}`}</Typography>
                
                    <div  className="phone-input">
                        <PhoneInput                      
                            style={{margin: '15px 0'}}
                            inputProps={{
                                type: 'tel',
                                required: true,
                                autoFocus: true
                            }}  
                            autoFormat={false}      
                            placeholder={translate('Phone Number')}
                            value={ this.state.phone }
                            onChange={ this.phoneInputHandler}
                        />
                    </div>

                    <div className="buttons">
                        <Button color="primary" onClick={this.cancel} >{translate('Cancel')}</Button>
                        <button className={'submit ' + (this.invalidInput() ? 'disabled' : '')} 
                            disabled = {this.invalidInput()}
                            onClick={(e) => {e.preventDefault(); this.save(this.state);}}>
                            {translate('Save')}
                        </button>
                    </div>

                </form>

            </div>
        );       
    }
}
 
const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps)(Profile);
