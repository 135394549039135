export default [{
    name: 'Bedrooms',
    count: 3,
    important: true
}, {
    name: 'Bathrooms',
    count: 2,
    important: true
}, {
    name: 'Kitchen'
}, {
    name: 'Living Room'
}, {
    name: 'Dining Room'
}, {
    name: 'Balconi'
}, {
    name: 'Front Yard'
}, {
    name: 'Back Yard'
}];