/* eslint-disable no-console */
export default {
    onUpdate: (registration) => {
        registration.waiting.postMessage({
            type: 'SKIP_WAITING'
        });
        console.log('post skip waiting message. Install updates now. Registration: ', registration);
    },
    onSuccess: (registration) => {
        console.log('service worker activates. Registration: ', registration);        
    }
};
