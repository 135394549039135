/* eslint-disable react/display-name */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import propTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import { connect } from 'react-redux';
import numeral from 'numeral';
import logo from '../../images/logo.png';
import './Navigation.css';
import unknownPerson from '../../images/unknown.png';
import * as routes from '../../constants/routes';
import ProfileLink from '../user/ProfileLink';
import Settings from '../settings/settings';
import MenuIcon from '@material-ui/icons/Menu';
import {translate} from '@yocasa/data-service';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    list: {
        width: 220,
    },
    items: {
        padding: 15,
        flexGrow: 1
    },
    greeting: {
        backgroundColor: '#3f51b5',
        padding: 18,
        color: '#fff',
        textAlign: 'right'
    },
    nested: {
        paddingLeft: theme.spacing(4),
    }
}));

const Navigation = (props) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = React.useState(false);
    const[openPropertySettings, setOpenPropertySettings] = React.useState(false);
    const[openPropertyReports, setOpenPropertyReports] = React.useState(false);
    
    const toggleDrawer = (open) => event => {
        const origin = event.target.className;
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if (typeof origin === 'string' && origin.includes('rcw-') && origin !== 'rcw-close')
            return;
        if (typeof origin === 'string' && origin.includes('avatar'))
            return;    
    
        setIsOpen(open);
    };

    const sideList = () => {

        return (
            <div
                className={classes.list}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
            >
                <List>
                    <div>
                        <ListItem button onClick={(e) =>{setOpenPropertyReports(!openPropertyReports); e.stopPropagation();}}>
                            <ListItemText primary={translate('Property Reservations')} />
                            {openPropertyReports ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openPropertyReports} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button className={classes.nested}>
                                    <Link className="nostyle" to={routes.PROPERTY_RESERVATIONS}>
                                        <ListItemText primary={translate('List Reservations')} />
                                    </Link>
                                </ListItem>
                                <ListItem button className={classes.nested}>
                                    <Link className="nostyle" to={routes.CREATE_RESERVATION}>
                                        <ListItemText primary={translate('Create a Reservation')} />
                                    </Link>
                                </ListItem>
                            </List>
                        </Collapse>
                        <ListItem button onClick={(e)=>{setOpenPropertySettings(!openPropertySettings); e.stopPropagation();}}>
                            <ListItemText primary={translate('Property Settings')} />
                            {openPropertySettings ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openPropertySettings} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button className={classes.nested}>
                                    <Link className="nostyle" to={routes.PROPERTY_BASICS}>
                                        <ListItemText primary={translate('Basic Info')} />
                                    </Link>
                                </ListItem>
                                <ListItem button className={classes.nested}>
                                    <Link className="nostyle" to={routes.PROPERTY_SPACES}>
                                        <ListItemText primary={translate('Living Spaces')} />
                                    </Link>
                                </ListItem>
                                <ListItem button className={classes.nested}>
                                    <Link className="nostyle" to={routes.PROPERTY_AMENITIES}>
                                        <ListItemText primary={translate('Amenities')} />
                                    </Link>
                                </ListItem>
                                <ListItem button className={classes.nested}>
                                    <Link className="nostyle" to={routes.PROPERTY_RULES}>
                                        <ListItemText primary={translate('Rules')} />
                                    </Link>
                                </ListItem>
                                <ListItem button className={classes.nested}>
                                    <Link className="nostyle" to={routes.PROPERTY_PRICING}>
                                        <ListItemText primary={translate('Pricing')} />
                                    </Link>
                                </ListItem>
                                <ListItem button className={classes.nested}>
                                    <Link className="nostyle" to={routes.CALENDAR}>
                                        <ListItemText primary={translate('Calendar')} />
                                    </Link>
                                </ListItem>
                                { 'id' in props.property && <ListItem button className={classes.nested}>
                                    <Link className="nostyle" to={routes.PROPERTY_PHOTOS}>
                                        <ListItemText primary={translate('Photos')} />
                                    </Link>
                                </ListItem>}
                            </List>
                        </Collapse>
                    </div>
                </List>
            </div>
        );
    };    
    
    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar disableGutters>
                    <Link to={routes.HOME}>
                        <IconButton size="small" color="inherit" aria-label="home">
                            <img src={logo} alt="Home" height="40px"/>
                        </IconButton>
                    </Link>
                    <div className={classes.items}>
                        {location.pathname !== routes.HOME && <IconButton 
                            onClick={() => props.history.goBack()}
                            size="small" 
                            color="inherit" 
                            aria-label="home">
                            <KeyboardArrowLeftIcon />
                        </IconButton>}
                        {props.pageTitle}
                    </div>
                    <div>
                        <IconButton
                            color="inherit"
                            onClick={toggleDrawer(true)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)}>
                            <div className="greeting text-font-big">{`${translate('Hello')} ${props.email}`}</div>
                            {sideList()}
                            <Divider/>
                            <Settings closeDrawer={toggleDrawer} />
                        </Drawer>
                    </div>
                    <ProfileLink 
                        hide={props.pageRoute !== '' && props.pageRoute !== null && (props.pageRoute === routes.SIGN_IN || props.pageRoute === routes.USER_PROFILE)} 
                        name={props.email}
                        avatar={props.avatar} 
                        phone={props.phone}
                        propertyId={props.property.id}
                        isAuthenticated={props.isAuthenticated} />                    
                </Toolbar>
            </AppBar>
        </div>
    );
};

Navigation.propTypes = {
    isAuthenticated: propTypes.bool.isRequired,
    email: propTypes.string,
    pageTitle: propTypes.string,
    pageRoute: propTypes.string,
    avatar: propTypes.string,
    phone: propTypes.string,
    property: propTypes.object,
    history: propTypes.object,
    roles: propTypes.object,
    dataLanguage: propTypes.string
};

const mapStateToProps = (state) => {
    const { user } = state;
    const { pageTitle, pageRoute} = state.settings;

    if (state.property.defaultCurrency === 'EUR')
        numeral.locale('fr');
    else if (state.property.defaultCurrency === 'CVE')
        numeral.locale('pt-cv');        

    return {
        isAuthenticated: user.isAuthenticated,
        roles: user.roles,
        email: user.firstName || user.email,
        pageTitle,
        pageRoute,
        avatar: user.avatar || unknownPerson,
        phone: user.phone || null,
        property: state.property,
        dataLanguage: state.settings.languageCode
    };
};

export default connect(mapStateToProps)(withRouter(Navigation));
