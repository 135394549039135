import React, { useEffect, useState } from 'react';
import {translate,logError, propertyUploadedImage, activeImagesRef, togglePropertyFlag} from '@yocasa/data-service';
import Button from '@material-ui/core/Button';
import propTypes from 'prop-types';
import { EDIT_PICTURES, HOME } from '../../constants/routes';
import {connect} from 'react-redux';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import './pictures.css';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import CaptionInput from './captionInput';

const EditPictures = (props) => {
    const [_getCaption, setGetCaption] = React.useState(props.edit);
    const [aspectRatio, setAspectRatio] =  useState(props.imageWidth/props.imageHeight);
    const [crop, setCrop] = useState({
        aspect: aspectRatio,
        unit: '%',
        width: 35,
    });
    const [imageRef, setImageRef] = useState(null);
    const [completedCrop, setCompletedCrop] = useState(null);

    useEffect(() => { 
        props.dispatch({
            type: 'SET_PAGE_TITLE',
            pageTitle: translate('Photos'),
            pageRoute: EDIT_PICTURES
        }); 
    }, []);

    const onSlide = (event, value) => {
        setAspectRatio(value);
        onChange({
            aspect: aspectRatio,
            unit: '%',
            width: 35,
        });
    };

    const onChange = (crop) => {
        setCrop(crop);
    };

    const onImageLoaded = image => {
        setImageRef(image);
    };
    
    const saveFile = (name, contentType, content, caption, tag) => {
        const task = activeImagesRef(props.propertyId).child(name)
            .put(content, {contentType,
                cacheControl: 'public, max-age=604800, immutable'}); 
    
        task
            .then(snapshot => snapshot.ref.getDownloadURL())
            .then((url) => {
                props.dispatch(propertyUploadedImage(props.propertyId, {                         
                    url, 
                    contentType, 
                    filename: name,
                    size: completedCrop.size,
                    width: completedCrop.width,
                    height: completedCrop.height,
                    caption: caption || null,
                    tag: tag || null,
                    sortOrder: props.sortOrder
                }));
            })
            .catch((error) => {
                logError({
                    date: new Date(),
                    code: 'edit-picture.jsx, save',
                    type: 'Database Storage activeImageRef put API call',
                    details: error.code
                });
            });        
    };

    const saveCroppedImage = (fileName, caption, tag) => {
        const canvas = document.createElement('canvas');
        const scaleX = imageRef.naturalWidth / imageRef.width;
        const scaleY = imageRef.naturalHeight / imageRef.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');
      
        ctx.drawImage(
            imageRef,
            completedCrop.x * scaleX,
            completedCrop.y * scaleY,
            completedCrop.width * scaleX,
            completedCrop.height * scaleY,
            0,
            0,
            completedCrop.width,
            completedCrop.height
        );
        const contentType = 'image/jpeg';
      
        canvas.toBlob(blob => {
            if (blob) {
                props.dispatch(togglePropertyFlag());
                blob.name = fileName;
                saveFile(fileName, contentType, blob, caption, tag);   
                completedCrop.size = blob.size;        
            }
        }, contentType);
    };

    const cancel = () => {
        setGetCaption(false);
        props.from 
            ? props.history.push({
                pathname: HOME,
                state: {
                    tab: 2
                }
            })
            : props.history.goBack();
    };

    const save = (caption, tag) => {
        setGetCaption(false);
        if (imageRef && completedCrop && !props.edit) {
            saveCroppedImage(props.src.name, caption, tag);
        } else if (props.edit && tag) {
            props.dispatch(propertyUploadedImage(props.propertyId, {                         
                filename: props.src.name,
                caption: caption,
                tag: tag
            }));
        }
        props.from 
            ? props.history.push({
                pathname: HOME,
                state: {
                    tab: 2
                }
            })
            : props.history.goBack();
    };

    return (
        <div className="picture-container">
            {_getCaption &&
                <CaptionInput save={save} cancel={cancel} open={_getCaption} tag={props.tag} caption={props.caption} /> }
            <div className="avatar-container">
                <ReactCrop 
                    src={props.image} 
                    crop={crop} 
                    crossorigin="anonymous" 
                    onImageLoaded={onImageLoaded}
                    onComplete={(crop) => setCompletedCrop(crop)}
                    keepSelection={true}
                    onChange={newCrop => onChange(newCrop)}/>
                <div className="buttons">
                    <div style={{flexGrow: '8'}}>
                        <Typography id="continuous-slider" gutterBottom>{translate('Width / Height')}</Typography>
                        <Slider value={aspectRatio} onChange={onSlide} style={{width: '50%'}}
                            min={0.70}
                            max={2}
                            step={0.05}
                            aria-labelledby="continuous-slider" />
                    </div>
                    <Button color="primary" onClick={cancel}>{translate('Cancel')}</Button>
                    <Button className={'submit'} onClick={()=>setGetCaption(true)}>{translate('Continue')}</Button>
                </div>
            </div>
        </div>
    );
};

EditPictures.propTypes = {
    history: propTypes.object,
    dispatch: propTypes.func,
    image: propTypes.string,
    src: propTypes.object,
    propertyId: propTypes.number,
    imageWidth: propTypes.number,
    imageHeight: propTypes.number,
    from: propTypes.string,
    edit: propTypes.bool,
    tag: propTypes.string,
    caption: propTypes.string,
    sortOrder: propTypes.number
};

const mapStateToProps = (state, ownProps) => {

    return {
        image: ownProps.location.state.asUrl,
        src: ownProps.location.state.src,
        propertyId: state.property.id,
        imageHeight: ownProps.location.state.imageHeight,
        imageWidth: ownProps.location.state.imageWidth,
        from: ownProps.location && ownProps.location.state ? ownProps.location.state.from : '',
        edit: ownProps.location && ownProps.location.state && ownProps.location.state.edit,
        tag: ownProps.location && ownProps.location.state ? ownProps.location.state.tag : '',
        caption: ownProps.location && ownProps.location.state ? ownProps.location.state.caption : '',
        sortOrder: ownProps.location && ownProps.location.state ? ownProps.location.state.sortOrder : 0
    };
};

export default connect(mapStateToProps)(EditPictures);
