import React from 'react';
import propTypes from 'prop-types';
import allAmenities from '../../constants/AmenitiesList';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import {translate, saveCurrentProperty} from '@yocasa/data-service';
import FormControl from '@material-ui/core/FormControl';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {connect} from 'react-redux';
import {PROPERTY_AMENITIES} from '../../constants/routes';
import './features.css';

const DisplayAmenity = ({amenity, existing, index, updateAmenities}) => {
    const alreadyInput = existing && existing.length>0;
    const [value, setValue] = React.useState(alreadyInput);
    const [info, setInfo] = React.useState((alreadyInput && existing[0].info) || '');
    const [important, setImportant] = React.useState((alreadyInput && 'important' in existing[0] && existing[0].important) || false); 

    const handleChange = (event) => {
        const name = event.target.name.split('-')[1];
        let change = '';

        switch (name) {
            case 'exists':
                change = event.target.checked;
                setValue(change);
                if (!change) {
                    setInfo('');
                    setImportant(false);
                }
                break;
            case 'info':
                change = event.target.value;
                setInfo(change);
                break;
            case 'important':
                change = event.target.value === 'true' || event.target.value === true;
                setImportant(change);
                break;
            default:
        }

        updateAmenities(event.target.name, change);
    };

    return (
        <div  className={index%2 === 0 ? 'amenity even' : 'amenity odd'}>
            <FormGroup row>
                <FormControlLabel 
                    className="exists"
                    control={<Checkbox checked={value} name={`${amenity.name}-exists`} onChange={handleChange} />}
                    label={translate(amenity.name)}
                />
            </FormGroup>

            {value && <div className="important">
                <div className="title">{translate('Highligh in cover page')}</div>
                <RadioGroup name={`${amenity.name}-important`} value={important} onChange={handleChange}>
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                </RadioGroup>            
            </div>}

            {value && <div className="info">
                <FormControl>
                    <label>{translate('Description')}</label>
                    <TextareaAutosize
                        name={`${amenity.name}-info`} placeholder={`${translate('Optional information about the')} ${translate(amenity.name)}`} value={info} onChange={handleChange}/>
                </FormControl>
            </div>}            
        </div>
    );
};
DisplayAmenity.propTypes = {
    amenity: propTypes.object,
    existing: propTypes.any,
    updateAmenities: propTypes.func,
    index: propTypes.number
};

const PropertyAmenities = ({dispatch, pid, amenities}) => {
    const [dataChanged, setDataChanged] = React.useState(false);

    React.useEffect(() => {
        dispatch && dispatch({
            type: 'SET_PAGE_TITLE',
            pageTitle: translate('Property Amenities'),
            pageRoute: PROPERTY_AMENITIES
        }); 
    }, [pid]);

    const saveAmenities = () => {
        saveCurrentProperty(pid, { amenities: amenities });
        setDataChanged(false);
    };

    const updateAmenities = (event, value) => {
        const action = event.split('-')[1];
        const name = event.split('-')[0];
        const amenity = amenities.filter(exist => exist.name.toLowerCase() === name.toLocaleLowerCase());

        switch (action) {
            case 'exists':
                if (value && !amenity || amenity.length === 0) amenities.push({name});
                else if (!value && amenity && amenity.length > 0) {
                    const pos = amenities.findIndex(item => item.name.toLowerCase() === name.toLowerCase()) ;
                    if (pos >= 0) amenities.splice(pos, 1);
                }
                break;
            case 'info':
                amenity[0].info = value;
                break;
            case 'important':
                amenity[0].important = value;
                break;
            default:
        }
        setDataChanged(true);
    };

    return (
        <div className="amenities-container">
            <div className="amenities">
                {allAmenities.map((amenity, index) => <DisplayAmenity 
                    key={amenity.name} 
                    amenity={amenity} 
                    existing={amenities && amenities.filter(exist => exist.name.toLowerCase() === amenity.name.toLocaleLowerCase())}
                    index={index}
                    updateAmenities={updateAmenities} />)}
            </div>
            <div style={{textAlign: 'center'}}>
                <Button disabled={!dataChanged} variant="contained" color="secondary" onClick={saveAmenities}>{translate('Save')}</Button>
            </div>
        </div>
    );
};

PropertyAmenities.propTypes = {
    amenities: propTypes.array.isRequired,
    dispatch: propTypes.func,
    pid: propTypes.number.isRequired
};

const mapStateToProps = (state) => {
    const {property} = state;
    return {
        amenities: property.amenities,
        pid: property.id
    };
};

export default connect(mapStateToProps)(PropertyAmenities);
