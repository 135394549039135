import React from 'react';
import propTypes from 'prop-types';
import {translate, saveCurrentProperty} from '@yocasa/data-service';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import {currencySymbols} from '@yocasa/common';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import Settings from '../../settings/settings';
import Dialog from '@material-ui/core/Dialog';
import Discounts from './discounts';
import Fees from './fees';
import SupportedCurrencies from './supportedCurrencies';
import {connect} from 'react-redux';
import {PROPERTY_PRICING} from '../../../constants/routes';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Paper from '@material-ui/core/Paper';
import TabPanel from '../../utils/TabPanel';
import Button from '@material-ui/core/Button';

import '../features.css';

const Pricing = ({dispatch, pid, price, promotionalDiscount, freeGuests, occupancy, additionalGuestAmount, currencyLabel, discounts, fees, supportedCurrencies}) => {
    const [currentTab, setCurrentTab] = React.useState(0);
    const [dataChanged, setDataChanged] = React.useState(false);
 
    const [_price, setPrice] = React.useState(price ? price/100 : '');
    const [_promotionalDiscount, setDiscount] = React.useState(promotionalDiscount || 0);
    const [_freeGuests, setFreeGuests] = React.useState(freeGuests || occupancy);
    const [_additionalGuestAmount, setAdditionalGuestAmount] = React.useState(additionalGuestAmount/100 || 0);
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        dispatch && dispatch({
            type: 'SET_PAGE_TITLE',
            pageTitle: translate('Property Pricing'),
            pageRoute: PROPERTY_PRICING
        }); 
        setPrice(price/100 || 0);
        setDiscount(promotionalDiscount || 0);
        setFreeGuests(freeGuests || occupancy);
        setAdditionalGuestAmount(additionalGuestAmount/100 || 0);
    }, [pid]);
    
    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const getItems = () => {
        const items = [];
        for (let i=1; i<=occupancy; i++) items.push(i);
        return items;
    };
    const items = getItems(); 

    const savePricing = () => {
        saveCurrentProperty(pid, {
            price: _price * 100,
            promotionalDiscount: _promotionalDiscount,
            freeGuests: _freeGuests,
            additionalGuestAmount: _additionalGuestAmount * 100
        });
        setDataChanged(false);
    };

    const handleChange = (value, setValue) => {
        let change = '';

        change = + value;
        if (change >=0) {
            setValue(value);
            setDataChanged(true);
        }
    };

    const pricing = () => (
        <div className="pricing">
            <Dialog open={open}>
                <p 
                    className="link-style"
                    style={{textAlign: 'right', margin: '0 10px'}}
                    onClick={()=>setOpen(false)}>x
                </p>
                <Settings style={{padding:'20px'}} />
            </Dialog>
            <div className="prices">
                <FormControl className="price">
                    <InputLabel className="title">{translate('Price per day')}</InputLabel>
                    <Input
                        inputProps={{size:9, autoFocus: 'autoFocus', autoComplete:'off'}}
                        value={_price}
                        onChange={(e) => handleChange(e.target.value, setPrice)}
                        startAdornment={<InputAdornment position="start">{currencySymbols[currencyLabel] || '$'}</InputAdornment>}
                        endAdornment={<InputAdornment position="end">.00</InputAdornment>}
                    />
                    <FormHelperText>
                        <span 
                            className="link-style" 
                            onClick={()=>setOpen(true)}>
                            {translate(supportedCurrencies[currencyLabel])}
                        </span>
                    </FormHelperText>
                </FormControl>            
                <FormControl className="price">
                    <InputLabel className="title">{translate('Promotional')}</InputLabel>
                    <Input 
                        inputProps={{size:3}}
                        endAdornment={<InputAdornment position="end">%</InputAdornment>}
                        value={_promotionalDiscount} 
                        onChange={(e)=> handleChange(e.target.value, setDiscount)} />
                    <FormHelperText>{translate('Discount from price')}</FormHelperText>
                </FormControl>            
                <FormControl className="price">
                    <InputLabel className="title">{translate('Max guests')}</InputLabel>
                    <Select
                        value={_freeGuests}
                        onChange={(e)=> {setFreeGuests(e.target.value*1); setDataChanged(true);}}>
                        {items.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
                    </Select> 
                    <FormHelperText>{translate('For set daily price')}</FormHelperText>
                </FormControl>
                {_freeGuests < occupancy && <FormControl className="price">
                    <InputLabel className="title">{translate('Daily fee')}</InputLabel>
                    <Input
                        inputProps={{size:9}}
                        value={_additionalGuestAmount}
                        onChange={(e)=> handleChange(e.target.value, setAdditionalGuestAmount)}
                        startAdornment={<InputAdornment position="start">{currencySymbols[currencyLabel] || '$'}</InputAdornment>}
                        endAdornment={<InputAdornment position="end">.00</InputAdornment>}
                    />
                    <FormHelperText>{translate('Per additional guest')}</FormHelperText>
                </FormControl>}            
            </div>
        </div>
    );

    return (
        <div className="pricing-container">
            <Paper square>
                <Tabs
                    value={currentTab}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                    onChange={handleTabChange}>
                    <Tab label={translate('Currencies')} />
                    <Tab label={translate('Price')} />
                    <Tab label={translate('Discounts')} />
                    <Tab label={translate('Fees')} />
                </Tabs>
            </Paper> 
            <TabPanel value={currentTab} index={0}>
                <SupportedCurrencies pid={pid} supportedCurrencies={supportedCurrencies} defaultCurrency={currencyLabel} />
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
                {pricing()}
                <div style={{textAlign: 'center'}}>
                    <Button  disabled={!dataChanged} variant="contained" color="secondary" onClick={savePricing}>{translate('Save')}</Button>
                </div>
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
                <Discounts pid={pid} discounts={discounts.filter(x => x.id !== 'D_Coupon')} />
            </TabPanel>
            <TabPanel value={currentTab} index={3}>
                <Fees pid={pid} fees={fees.filter(x => x.id !== 'F_LocalTaxes')} currencyLabel={currencyLabel} />
            </TabPanel>
        </div>
    );
};

Pricing.propTypes = {
    pid: propTypes.number,
    price: propTypes.number,
    promotionalDiscount: propTypes.number,
    freeGuests: propTypes.number,
    discounts: propTypes.array,
    fees: propTypes.array,
    occupancy: propTypes.number,
    additionalGuestAmount: propTypes.number,
    currencyLabel: propTypes.string,
    supportedCurrencies: propTypes.object,
    dispatch: propTypes.func
};

const mapStateToProps = (state) => {
    const {property} = state;

    return {
        pid: property.id,
        price: property.price || '',
        promotionalDiscount: property.promotionalDiscount, 
        freeGuests: property.freeGuests,
        additionalGuestAmount: property.additionalGuestAmount,
        discounts: property.discounts || [],
        fees: property.fees || [],
        occupancy: property.occupancy,
        currencyLabel: property.defaultCurrency,
        supportedCurrencies: property.supportedCurrencies
    };
};

export default connect(mapStateToProps)(Pricing);
