export default [ {
    id: 'D_Weekly',
    description: 'Weekly Discount',
    percent: 0,
    minimumDays: 7,
    maximumDays: 27,
    info: '',
    startDate: null,
    endDate: null
},{            
    id: 'D_Monthty',
    description: 'Monthly Discount',
    percent: 0,
    minimumDays: 28,
    info: '',
    startDate: null,
    endDate: null
},{            
    id: 'D_Coupon',
    description: 'Coupon Discount',
    percent: 0,
    info: '',
    couponId: 'special1',
    startDate: new Date(),
    endDate: new Date()
}];