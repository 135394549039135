import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import propTypes from 'prop-types';
import LoginForm from './LoginForm';
import CreateUserForm from './CreateUserForm';
import RecoverPasswordForm from './ResetPasswordForm';
import Spinner from '../utils/SpinnerModal';
import {translate, createUser, signIn, sendPasswordResetEmail} from '@yocasa/data-service';
import { SIGN_IN, HOME } from '../../constants/routes';
import { Redirect } from 'react-router-dom';

const LoginPage = (props) => {
    const [serverError, setServerError] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [action, setAction] = React.useState('Login');

    useEffect(() => { 
        props.dispatch({
            type: 'SET_PAGE_TITLE',
            pageTitle: translate(action),
            pageRoute: SIGN_IN
        }); 
    });

    const toggleForm = (form) => setAction(form);

    const signup = (data) => {
        setServerError(null);
        setIsLoading(true);
        data.source = 'registration';
        createUser(data.email, data.password1, data)
            .then(() => {
                setIsLoading(false);
                setMessage('Account successfully created. Please sign-in');
            })
            .catch(error => {
                setServerError(typeof error === 'string' || (error.code && error.code.includes('internal-error')) ? JSON.parse(error.message).error: error);
                setIsLoading(false);
            });
    };

    const login = (data) => {
        setServerError(null);
        setIsLoading(true);
        signIn(data.email, data.password1)
            .then(() => {
                props.history.push('/');
            })
            .catch(error => {
                setServerError(typeof error === 'string' || (error.code && error.code.includes('internal-error')) ? JSON.parse(error.message).error: error);
                setIsLoading(false);
            });
    };

    const recover = (data) => {
        setServerError(null);
        setIsLoading(true);
        sendPasswordResetEmail(data.email)
            .then(() => {
                setIsLoading(false);
                setMessage(`Please follow the link in the email sent to you
                to reset your password. Then come back here to sign-in`);
            })
            .catch(error => {
                setServerError(typeof error === 'string' || error.code.includes('internal-error') ? JSON.parse(error.message).error: error);
                setIsLoading(false);
            });
    };

    return (
        <div>
            {
                props.isAuthenticated ? <Redirect to={HOME}/> :
                    <div>
                        {
                            action === 'Login'
                                ? <LoginForm  message={message} error={serverError} login={login} toggleForm={toggleForm} />
                                : action === 'Create Account'
                                    ? <CreateUserForm message={message} error={serverError} signup={signup} toggleForm={toggleForm} />
                                    : <RecoverPasswordForm  message={message} error={serverError} recover={recover} toggleForm={toggleForm} />
                        }
                        <Spinner isOpen={isLoading} />
                    </div>
            }
        </div>
    );
};

LoginPage.propTypes = {
    dispatch: propTypes.func.isRequired,
    history: propTypes.object.isRequired,
    isAuthenticated: propTypes.bool
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.user.isAuthenticated
});

export default connect(mapStateToProps)(LoginPage);
