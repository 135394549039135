import { auth, user, store, userSignedIn, userSignedOut } from '@yocasa/data-service';

const userIsSignedin = (authUser) => {
    let dbUser = null;
    user(authUser.uid)
        .once('value')
        .then(snapshot => {
            dbUser = snapshot.val();
            // default empty roles
            if (dbUser && !dbUser.roles) {
                store.dispatch(userSignedOut());
            }
            store.dispatch(userSignedIn(
                {
                    uid: authUser.uid,
                    email: authUser.email,
                    ...dbUser,
                }
            ));
        });
    
};

export default () => {
    auth.onAuthStateChanged(authUser => {
        authUser
            ? userIsSignedin(authUser)
            : store.dispatch(userSignedOut());        
    });

};
