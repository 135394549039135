import React from 'react';
import Dates from './components/Dates';
import GuestInput from './components/GuestInput';
import {PRICE_PAGE} from './components/reservation-input-pages';
import Button from '@material-ui/core/Button';
import {translate} from '@yocasa/data-service';
import {connect} from 'react-redux';
import propTypes from 'prop-types';
import Settings from '../settings/settings';

const DetailsInput = ({reservation, gotoPage}) => {

    const navButtons = () => (
        <div className="buttons">
            <Button
                onClick={()=> gotoPage(0)} 
                variant="contained"
                className="reservation-reserve-button" >
                {translate('Previous')}
            </Button>
            <Button
                onClick={()=> gotoPage(PRICE_PAGE)} 
                variant="contained" color="secondary"
                disabled={!reservation.startDate || !reservation.endDate } 
                className="reservation-reserve-button" >
                {translate('Next')}
            </Button>
        </div>
    );
    
    return (
        <div>
            <div className="page2-container">
                <Dates />
                <GuestInput />
                <Settings type='currency' for='reservation' />
                {navButtons()}
            </div>
        </div>   
    );
};
DetailsInput.propTypes = {
    reservation: propTypes.object.isRequired,
    gotoPage: propTypes.func.isRequired,
    dispatch: propTypes.func
};

const mapStateToProps = (state) => ({
    reservation: state.reservation
});

export default connect(mapStateToProps)(DetailsInput);