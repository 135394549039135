import React, { Component } from 'react';
import {users} from '@yocasa/data-service';
import propTypes from 'prop-types';
import Spinner from '../utils/SpinnerModal';
import './Admin.css';

class AdminPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            users: [],
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        users().on('value', snapshot => {
            const usersObject = snapshot.val();
            const usersList = Object.keys(usersObject).map(key => ({
                ...usersObject[key],
                uid: key,
            }));
            this.setState({
                users: usersList,
                loading: false,
            });
        });
    }

    componentWillUnmount() {
        users().off();
    }
    
    
    render() {
        return (
            <div className="user-list">
                <Spinner isOpen={this.state.loading} />
                <UserList users={this.state.users} />
            </div>
        );
    }
}

const UserList = (props) => (
    <div>
        <table>
            <thead>
                <tr><th>User</th><th>Email</th><th>Roles</th><th>Action</th></tr>
            </thead>
            <tbody>
                {props.users.map((user, index) => (
                    user.email && <User key={user.uid} user={user} index={index} />
                ))}
            </tbody>
        </table>

    </div>
);
UserList.propTypes = {
    users: propTypes.array
};

const User = (props) => {

    const {user} = props;

    const makeAdmin = (e) => {
        e.preventDefault();
        user(user.uid).update({roles: {admin: 'admin'}})
            .then(() => user.roles = {admin: 'admin'});
    };

    const removeAdmin = (e) => {
        e.preventDefault();
        user(user.uid).update({roles: {}})
            .then(() => user.roles = {});
    };

    return ( 
        <tr className={(props.index % 2) && 'alternate'}>
            <td>{user.firstName} {' '} {user.lastName}</td>
            <td>{user.email}</td>
            <td>{user.roles && Object.keys(user.roles).map(role => role + ' . ')}</td>
            <th>
                {
                    user.roles && 'admin' in user.roles
                        ? <button onClick={removeAdmin}> Remove Admin</button>
                        : <button onClick={makeAdmin}> Make Admin</button>
                }
            </th>
        </tr>
    );
};
User.propTypes = {
    user: propTypes.object,
    index: propTypes.number
}; 

export default AdminPage;