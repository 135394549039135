import React, {useEffect, useState} from 'react';
import propTypes from 'prop-types';
import {connect} from 'react-redux';
import { getPropertyMessages } from '@yocasa/data-service';
import moment from 'moment';
import {messageTypes} from '@yocasa/common';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import {translate} from '@yocasa/data-service';
import FormHelperText from '@material-ui/core/FormHelperText';
import './messages.css';

const Message = ({message}) => {
    const displayMessage = `${message.type} | ${moment(message.date).format('llll')} | ${message.platform} | ${message.screenWidth}x${message.screenHeight} | app: ${!message.webMode}`;

    return (
        <li>
            { displayMessage }
            {message.name && ` | ${message.name} | ${message.email} | ${message.phone}`}
            {message.type === messageTypes.NAVIGATION && <p>{message.pathname}</p>}
            { message.type === messageTypes.GUEST_UPLOADED_IMAGE && <p>{message.url}</p>}
            {message.type === messageTypes.RESERVATION_CREATED && <p>{`rid: ${message.startDate} | Start date: ${moment(message.startDate).format('llll')} | Days: ${message.days}`}</p>}
            {message.type === messageTypes.RESERVATION_PAYMENT_RECEIVED && <p>{`rid: ${message.reservationId} | amount: ${message.paymentMade}`}</p>}
            {message.type === messageTypes.RESERVATION_STATUS_UPDATED && <p>{`rid: ${message.reservationId} | status: ${message.status}`}</p>}
            {message.type === messageTypes.GUEST_GAVE_FEEDBACK && <p>{`rating: ${message.rating} | willStayAgain: ${message.willStayAgain}`}</p>}
            <hr/>
        </li>
    );
};
Message.propTypes = {
    message: propTypes.object
};

const Messages = ({messages, pid, name}) =>
{
    const noSelection = 'NONE';
    const [daysToView, setDaysToView] = useState(1);
    const [gotMessages, setGotMessages] = useState(false);
    const [eventsToExclude, setEventsToExclude] = useState([messageTypes.NAVIGATION]);

    useEffect(() => { 
        if (!gotMessages) {
            getPropertyMessages(pid, new Date().valueOf() - daysToView * (24 * 3600 * 1000));
            setGotMessages(true);
        }
    }, [messages, pid, name, daysToView, gotMessages]);

    const eventTypes = [
        noSelection,
        messageTypes.GUEST_SIGNED_IN,
        messageTypes.GUEST_SIGNED_OUT,
        messageTypes.NAVIGATION,
        messageTypes.USER_CREATED,
        messageTypes.RESERVATION_CREATED,
        messageTypes.RESERVATION_PAYMENT_RECEIVED,
        messageTypes.RESERVATION_STATUS_UPDATED,
        messageTypes.GUEST_GAVE_FEEDBACK,
        messageTypes.GUEST_UPLOADED_IMAGE,
        messageTypes.GUEST_UPDATED_PROFILE,
        messageTypes.NONPAYMENT
    ];

    const handleEventsToExcludeChange = e => {
        if (e.target.value.includes(noSelection) && e.target.value[0] !== noSelection) {
            setEventsToExclude([noSelection]);
        }
        else {
            const excluded = e.target.value.filter(value => value !== noSelection);
            setEventsToExclude(excluded);
        }
        setGotMessages(false);
    };

    return (
        <div className="message-container">
            <div className="message-input">
                <FormControl>
                    <Select
                        id="demo-simple-select"
                        value={daysToView}
                        inputProps={{ 'aria-label': 'Without label' }}
                        onChange={(e) => {  setDaysToView(e.target.value); setGotMessages(false); }}
                    >
                        <MenuItem value={1}>One</MenuItem>
                        <MenuItem value={7}>Seven</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                        <MenuItem value={365}>More than Thirty</MenuItem>
                    </Select>
                    <FormHelperText>{translate('Days to View')}</FormHelperText>
                </FormControl>
                <FormControl>
                    <Select
                        id="demo-simple-select"
                        value={eventsToExclude}
                        multiple
                        inputProps={{ 'aria-label': 'Without label' }}
                        onChange={handleEventsToExcludeChange}
                    >
                        { eventTypes.map(type => <MenuItem value={type} key={type}>{type}</MenuItem>) }
                    </Select>
                    <FormHelperText>{translate('Events to Exclude')}</FormHelperText>
                </FormControl>
            </div>
            <div className="message-output">
                {messages 
                    ? <ol>
                        {messages.map(message => 
                            (eventsToExclude.includes(noSelection) || !eventsToExclude.includes(message.type)) && <Message key={message.date} message={message} />
                        )}
                    </ol>
                    : <div>No activity yet for {name}.</div>
                }
            </div>
        </div>
    );
};

Messages.propTypes = {
    messages: propTypes.array,
    name: propTypes.string,
    pid: propTypes.number
};

const mapStateToProps = (state) => ({
    messages: state.property.messages ? state.property.messages : null,
    pid: state.property.id,
    name: state.property.name
});


export default connect(mapStateToProps)(Messages);